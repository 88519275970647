<div class="modal fade" [id]="modalId" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalCenteredLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="modalCenteredLabel">{{ header }}</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body"> 
        {{body}} {{message}}
		<!-- <div *ngIf="isConfirmProductFile">
			<input type="text" placeholder="{{versionInput}}" (input)="updateVersionInput($event.target.value)">
		</div> -->
		<div *ngIf="isConfirmProductFile">
			<input type="text" placeholder="{{versionInput}}" [value]="versionInput">
		</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
				<button type="button" class="btn btn-primary" (click)="submit()">Confirm</button>
			</div>
		</div>
	</div>
</div>
