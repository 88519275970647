import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../shared/smartadmin.config';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
@Injectable()
export class LineService {
  private access_token: any;
  constructor(private http: HttpClient) {}

  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + config.API_URL + url).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  public addNewLine(packageModel: any): Observable<any> {
    let body = JSON.stringify(packageModel);
    return this.http.post(config.API_ENDPOINT + 'line/save', body);
  }
  public updateLine(packageModel: any): Observable<any> {
    let body = JSON.stringify(packageModel);
    return this.http.post(config.API_ENDPOINT + 'line/update', body);
  }
  public getAllLines(line_model): Observable<any> {
    let body = JSON.stringify(line_model);
    return this.http.post(config.API_ENDPOINT + 'line/listall', body);
  }

  public getLineById(line_id: any): Observable<any> {
    return this.http.get(config.API_ENDPOINT + 'line/get?id=' + line_id);
  }

  public deleteLine(line: any): Observable<any> {
    let body = JSON.stringify(line);
    return this.http.post(config.API_ENDPOINT + 'line/delete', body);
  }

  public deleteManyWithPackages(line_model): Observable<any> {
    let body = JSON.stringify(line_model);
    return this.http.post(
      config.API_ENDPOINT + 'line/deletemanywithpackages',
      body
    );
  }

  private handleError(error: any) {
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
