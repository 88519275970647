import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../shared/smartadmin.config';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
@Injectable()
export class PriceOverviewService {
  private access_token: any;
  constructor(private http: HttpClient) {}

  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + config.API_URL + url).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  public getAllPriceOverviewVersions(searchModel): Observable<any> {
    let body = JSON.stringify(searchModel);
    return this.http.post(
      config.API_ENDPOINT + 'priceoverview/listversions',
      body
    );
  }

  public copyNewCostOverview(costOverviewModel: any): Observable<any> {
    let body = JSON.stringify(costOverviewModel);
    return this.http.post(config.API_ENDPOINT + 'cost/copy', body);
  }

  private handleError(error: any) {
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
