import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ForgotComponent } from './pages/auth/forgot/forgot.component';
import { LockedComponent } from './pages/auth/locked/locked.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotComponent,
      },
      {
        path: 'locked',
        component: LockedComponent,
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'error',
        component: ErrorPageComponent,
      },
      {
        path: 'product-files',
        loadChildren: () =>
          import('./pages/product-files/product-file-list.module').then(
            (module) => module.ProductFileListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'priceoverview',
        loadChildren: () =>
          import('./pages/costoverview/costoverview.module').then(
            (module) => module.CostOverviewModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'priceoverview-templates',
        loadChildren: () =>
          import(
            './pages/price-overview-core/price-overview-templates/price-overview-templates.module'
          ).then((module) => module.PriceOverviewTemplatesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'product-file-detail/:productFileId',
        loadChildren: () =>
          import('./pages/product-file-detail/product-file-detail.module').then(
            (module) => module.ProductFileDetailModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'uploader',
        loadChildren: () =>
          import('./pages/uploader-v2/uploader-showcase-v2.module').then(
            (module) => module.UploaderShowcaseV2Module
          ),
      },
      {
        path: 'add-package',
        loadChildren: () =>
          import('./pages/package-core/add-package.module').then(
            (module) => module.AddPackageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-rule-formula',
        loadChildren: () =>
          import('./pages/formula-generator/formula-generator.module').then(
            (module) => module.FormulaGeneratorModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'packages',
        loadChildren: () =>
          import('./pages/package-core/list/package-list.module').then(
            (module) => module.PackageListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'package-edit/:package_id',
        loadChildren: () =>
          import('./pages/package-core/edit/edit-package.module').then(
            (module) => module.EditPackageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-line',
        loadChildren: () =>
          import('./pages/line-core/add/add-line.module').then(
            (module) => module.AddLineModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'lines',
        loadChildren: () =>
          import('./pages/line-core/list/line-list.module').then(
            (module) => module.LineListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'line-edit/:line_id',
        loadChildren: () =>
          import('./pages/line-core/edit/edit-line.module').then(
            (module) => module.EditLineModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-alloy-wheel',
        loadChildren: () =>
          import(
            './pages/definitions/alloy-wheel-core/add/add-alloy-wheel.module'
          ).then((module) => module.AddAlloyWheelModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'alloywheels',
        loadChildren: () =>
          import(
            './pages/definitions/alloy-wheel-core/list/alloy-wheel-list.module'
          ).then((module) => module.AlloyWheelListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'alloy-wheel-edit/:alloywheel_id',
        loadChildren: () =>
          import(
            './pages/definitions/alloy-wheel-core/edit/edit-alloy-wheel.module'
          ).then((module) => module.EditAlloyWheelModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-engine-type',
        loadChildren: () =>
          import(
            './pages/definitions/engine-type-core/add/add-engine-type.module'
          ).then((module) => module.AddEngineTypeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'enginetypes',
        loadChildren: () =>
          import(
            './pages/definitions/engine-type-core/list/engine-type-list.module'
          ).then((module) => module.EngineTypeListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'engine-type-edit/:alloywheel_id',
        loadChildren: () =>
          import(
            './pages/definitions/engine-type-core/edit/edit-engine-type.module'
          ).then((module) => module.EditEngineTypeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-exterior-color',
        loadChildren: () =>
          import(
            './pages/definitions/exterior-color-core/add/add-exterior-color.module'
          ).then((module) => module.AddExteriorColorModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'exteriorcolors',
        loadChildren: () =>
          import(
            './pages/definitions/exterior-color-core/list/exterior-color-list.module'
          ).then((module) => module.ExteriorColorListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'exterior-color-edit/:exteriorcolor_id',
        loadChildren: () =>
          import(
            './pages/definitions/exterior-color-core/edit/edit-exterior-color.module'
          ).then((module) => module.EditExteriorColorModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-upholstery',
        loadChildren: () =>
          import(
            './pages/definitions/upholstery-core/add/add-upholstery.module'
          ).then((module) => module.AddUpholsteryModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'upholsteries',
        loadChildren: () =>
          import(
            './pages/definitions/upholstery-core/list/upholstery-list.module'
          ).then((module) => module.UpholsteryListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'upholstery-edit/:upholstery_id',
        loadChildren: () =>
          import(
            './pages/definitions/upholstery-core/edit/edit-upholstery.module'
          ).then((module) => module.EditUpholsteryModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-serie',
        loadChildren: () =>
          import(
            './pages/definitions/product-series/add/add-serie.module'
          ).then((module) => module.AddSerieModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'series',
        loadChildren: () =>
          import(
            './pages/definitions/product-series/list/serie-list.module'
          ).then((module) => module.SerieListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'serie-edit/:serie_id',
        loadChildren: () =>
          import(
            './pages/definitions/product-series/edit/edit-serie.module'
          ).then((module) => module.EditSerieModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-model',
        loadChildren: () =>
          import(
            './pages/definitions/product-models/add/add-model.module'
          ).then((module) => module.AddModelModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'models',
        loadChildren: () =>
          import(
            './pages/definitions/product-models/list/model-list.module'
          ).then((module) => module.ModelListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'model-edit/:model_id',
        loadChildren: () =>
          import(
            './pages/definitions/product-models/edit/edit-model.module'
          ).then((module) => module.EditModelModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-option',
        loadChildren: () =>
          import('./pages/definitions/options-core/add/add-option.module').then(
            (module) => module.AddOptionModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'import-options',
        loadChildren: () =>
          import(
            './pages/definitions/options-core/import/import-options.module'
          ).then((module) => module.ImportOptionsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'options',
        loadChildren: () =>
          import(
            './pages/definitions/options-core/list/option-list.module'
          ).then((module) => module.OptionListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'option-edit/:option_id',
        loadChildren: () =>
          import(
            './pages/definitions/options-core/edit/edit-option.module'
          ).then((module) => module.EditOptionModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-trim',
        loadChildren: () =>
          import('./pages/definitions/trim-core/add/add-trim.module').then(
            (module) => module.AddTrimModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'trims',
        loadChildren: () =>
          import('./pages/definitions/trim-core/list/trim-list.module').then(
            (module) => module.TrimListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'trim-edit/:trim_id',
        loadChildren: () =>
          import('./pages/definitions/trim-core/edit/edit-trim.module').then(
            (module) => module.EditTrimModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-body-type',
        loadChildren: () =>
          import(
            './pages/definitions/body-type-core/add/add-body-type.module'
          ).then((module) => module.AddBodyTypeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'body-types',
        loadChildren: () =>
          import(
            './pages/definitions/body-type-core/list/body-type-list.module'
          ).then((module) => module.BodyTypeListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'body-type-edit/:body_type_id',
        loadChildren: () =>
          import(
            './pages/definitions/body-type-core/edit/edit-body-type.module'
          ).then((module) => module.EditBodyTypeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-standart-option',
        loadChildren: () =>
          import(
            './pages/definitions/standart-options/add/add-standart-option.module'
          ).then((module) => module.AddStandartOptionModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-cost-overview',
        loadChildren: () =>
          import(
            './pages/price-overview-core/add/add-price-overview.module'
          ).then((module) => module.AddPriceOverviewModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-price-rule',
        loadChildren: () =>
          import('./pages/costoverview/add/add-cost-rule.module').then(
            (module) => module.AddCostRuleModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'edit-price-rule/:id,makecode,seriecode,bodytype,modelcode,profile,version',
        loadChildren: () =>
          import('./pages/costoverview/edit/edit-cost-rule.module').then(
            (module) => module.EditCostRuleModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'price-rule-list',
        loadChildren: () =>
          import('./pages/costoverview/list/cost-rule-list.module').then(
            (module) => module.CostRuleListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'import-discounts',
        loadChildren: () =>
          import(
            './pages/definitions/package-discounts-core/import/import-discounts.module'
          ).then((module) => module.ImportDiscountsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'discount-list',
        loadChildren: () =>
          import(
            './pages/definitions/package-discounts-core/list/discount-list.module'
          ).then((module) => module.DiscountListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'discount-edit/:package_id',
        loadChildren: () =>
          import(
            './pages/definitions/package-discounts-core/edit/edit-discount.module'
          ).then((module) => module.EditDiscountModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-discount',
        loadChildren: () =>
          import(
            './pages/definitions/package-discounts-core/add/add-discount.module'
          ).then((module) => module.AddDiscountModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'import-pricing-input',
        loadChildren: () =>
          import(
            './pages/price-core/pricing-input/import/import-pricing-input.module'
          ).then((module) => module.ImportPricingInputModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'pricing-input-list',
        loadChildren: () =>
          import(
            './pages/price-core/pricing-input/list/pricing-input-list.module'
          ).then((module) => module.PricingInputListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'pricing-input-edit/:input_id',
        loadChildren: () =>
          import(
            './pages/price-core/pricing-input/edit/edit-pricing-input.module'
          ).then((module) => module.EditPricingInputModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'sap-integration',
        loadChildren: () =>
          import(
            './pages/sapintegration/sap-integration/sap-integration.module'
          ).then((module) => module.SapIntegrationModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'list-sap-integration',
        loadChildren: () =>
          import(
            './pages/sapintegration/list-sap-integration/list-sap-integration.module'
          ).then((module) => module.ListSapIntegrationModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'sp-coefficient-list',
        loadChildren: () =>
          import('./pages/sp-coefficient/coefficient-option-list.module').then(
            (module) => module.CoefficientOptionListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'import-options-group',
        loadChildren: () =>
          import(
            './pages/definitions/options-core/options-group/import-options-group.module'
          ).then((module) => module.ImportOptionsGroupModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'options-group',
        loadChildren: () =>
          import(
            './pages/definitions/options-core/options-group-list/options-group-list.module'
          ).then((module) => module.OptionsGroupListModule),
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
