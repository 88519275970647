import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as jexcel from 'jexcel-pro';
import Tribute from 'tributejs';

@Component({
  selector: 'app-price-overview-table',
  templateUrl: './price-overview-table.component.html',
  styleUrls: ['./price-overview-table.component.scss'],
})
export class PriceOverviewTableComponent implements OnInit, AfterViewInit {
  @ViewChild('priceOverviewTable') priceOverviewTable: ElementRef;
  @Input() data;
  @Input() model;
  @Output() dataLoaded: EventEmitter<boolean> = new EventEmitter(false);
  firstRow = [];
  cellVariableMapper = [];
  profileNames = [];
  lineNames = [];
  pricingInput = [];
  pricingInputCellNames = [];
  rowCount = 0;
  tableHeaders = [];
  tableRef: any;
  selectedCell = {
    name: '',
    value: '',
    newValue: '',
    disabled: true,
  };
  percentRowNames = ['OTVCustoms', 'OTVListPrice'];
  percentRows = [8];
  noMaskCells = [
    {
      x: 3,
      y: 0,
    },
    {
      x: 3,
      y: 1,
    },
    {
      x: 3,
      y: 2,
    },
  ];
  newValueMapper = {};

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.initTable();
  }

  initTable(): void {
    const singlePackagePrices = this.generateSinglePackagePrices();
    const lineDiscountedPrices = this.generateLineDiscountedPrices();
    const takeRates = this.generateTakeRates();
    const discountedPricesWithCoefficient =
      this.generateDiscountedPricesWithCoefficient();
    this.lineNames = this.generateLineNames();
    if (this.data.pricinginput) {
      this.pricingInput = this.generatePricingInput();
    }
    this.firstRow = this.generateFirstRow(this.lineNames.length);
    const header = [
      this.firstRow,
      [
        'Model Code',
        this.data.ModelCode,
        '',
        this.data.FixedEuroTL,
        'Fixed €/TL',
      ],
      [
        'Model Name',
        this.model.description,
        '',
        this.data.MarketEuroTL,
        'Market €/TL',
      ],
      ['Engine disp for ÖTV', this.data.CC],
      [''],
      ['Single Package Price'].concat(singlePackagePrices),
      ['Discounted Price'].concat(lineDiscountedPrices),
      ['Discounted Price (Coefficient)'].concat(
        discountedPricesWithCoefficient
      ),
      ['Take Rate Estimation'].concat(takeRates),
      ['Profile'].concat(this.lineNames),
    ];
    this.tableRef = jexcel(this.priceOverviewTable.nativeElement, {
      data: header,
      columns: [
        {
          width: '233',
        },
      ],
      rows: [],
      nestedHeaders: [],
      defaultColAlign: 'left',
      defaultColWidth: 180,
      columnDrag: false,
      columnSorting: false,
      rowDrag: false,
      allowInsertRow: false,
      allowManualInsertRow: false,
      allowInsertColumn: false,
      allowManualInsertColumn: false,
      allowDeleteRow: false,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      tableOverflow: true,
      autoIncrement: true,
      autoCastings: true,
      parseFormulas: true,
      cache: false,
      freezeColumns: 1,
      tableWidth: this.priceOverviewTable.nativeElement.offsetWidth + 'px',
      tableHeight:
        document.getElementById('table-container').clientHeight - 50 + 'px',
      // license: environment.jexcelKey,
      license:
        'MTA1OThiNTQxODE5ZjRmY2U0NGFiMDNjMjgyN2EzM2Y5MTU2YmZjYzg1YjU2NTFjOGFlMjcwYTA2ZDhhMTU2ZGUxZjU1YjM0ZmVlMjljMGQwZDZmMDEzZGE4MzUxNzY1YWFlMjM2ZTM5MTczYWY3NzVhMzBmOGYwMTQ4ZjFmOTEsZXlKdVlXMWxJam9pVWtWQklGUmxhMjV2Ykc5cWFTSXNJbVJoZEdVaU9qRTJOelkwTVRreU1EQXNJbVJ2YldGcGJpSTZXeUp6YldGeWRIQnlhV05wYm1jdVltOXlkWE5oYm05MGIyMXZkR2wyTG1OdmJTSXNJbk50WVhKMGNISnBZMmx1WnkxMFpYTjBMbUp2Y25WellXNXZkRzl0YjNScGRpNWpiMjBpTENKemJXRnlkSEJ5YVdOcGJtY3RaR1YyTG1GNmRYSmxkMlZpYzJsMFpYTXVibVYwSWl3aWJHOWpZV3hvYjNOMElsMHNJbkJzWVc0aU9pSTFJbjA9',
      /* onbeforepaste: (instance, data, x, y) => {
        if (data && data[0] === '=') {
          return this.variableToCellName(data);
        }
      }, */
      onpaste: (el, data) => {
        /* console.log('@@@onpaste');
        console.log(data); */
        if (data && data.length) {
          data.forEach((cell) => {
            const value = cell.value || cell.newValue;
            const cellName = this.tableHeaders[+cell.x] + (+cell.y + 1);
            if (value && value[0] === '=') {
              this.newValueMapper[cellName] = this.cellNameToVariable(value);
            } else {
              delete this.newValueMapper[cellName];
            }
            // this.tableRef.setValueFromCoords(cell.x, cell.y, this.variableToCellName(cell.value));
          });
        }
      },
      onbeforechange: (el, cell, x, y, value) => {
        /* console.log('@@@onbeforechange');
        console.log(value); */
        const cellName = this.tableHeaders[+x] + (+y + 1);
        if (value && value[0] === '=') {
          /* this.newValueMapper[cellName] = value; */
          value = this.variableToCellName(value);
          return value;
        } /*  else {
          delete this.newValueMapper[cellName];
        } */
      },
      onevent: (type, container, cell: HTMLElement, col, row, value, g, h) => {
        if (type === 'onload') {
          this.initCustomFormulas();
          setTimeout(() => {
            this.maskOnLoad();
          }, 100);
        }
        /* console.log(type);
        console.log(col + '-' + row);
        console.log(container);
        console.log(cell);
        console.log(value);
        console.log(g);
        console.log(h); */
        if (type === 'onchange') {
          /* console.log('@@@onchange');
          console.log(value); */
          if (value) {
            this.maskCellValue(cell, +row);
          }
        }
        if (type === 'onselection') {
          this.selectedCell = {
            name: '',
            value: '',
            newValue: '',
            disabled: false,
          };
          row = col;
          col = cell;
          const cellName = this.tableHeaders[+col] + (+row + 1);
          if (this.newValueMapper[cellName]) {
            // value = this.cellNameToVariable(value);
            value = this.newValueMapper[cellName];
          } else {
            value = this.tableRef.getValue(cellName, false);
          }
          cell = this.tableRef.getCell(cellName);
          this.selectedCell = {
            name: cellName,
            value: value,
            newValue: value,
            disabled: cell.className.indexOf('readonly') > -1,
          };
        }
        if (type === 'oncreateeditor') {
          this.initTribute(value);
          const cellName = this.tableHeaders[+col] + (+row + 1);
          if (this.newValueMapper[cellName]) {
            value.value = this.newValueMapper[cellName];
          }
        }
        if (type === 'oneditionstart') {
          /* setTimeout(() => {
            const input = cell.querySelector('input');
            this.initTribute(input);
          }, 500);
          const cellName = this.tableHeaders[+col] + (+row + 1);
          let newValue = this.tableRef.getValue(cellName, false);
          if (newValue && newValue[0] === '=') {
            newValue = this.cellNameToVariable(newValue);
            this.tableRef.setValueFromCoords(col, row, newValue);
          } */
        }
        if (type === 'oneditionend') {
          const cellName = this.tableHeaders[+col] + (+row + 1);
          if (value && value[0] === '=') {
            this.newValueMapper[cellName] = value;
          } else {
            delete this.newValueMapper[cellName];
          }
          /* console.log('@@@oneditionend');
          console.log(value); */
          /* if (value && value[0] === '=') {
            this.tableRef.setValueFromCoords(col, row, this.variableToCellName(value));
          } */
        }
      },
      updateTable: (el, cell, col, row, source, value, id) => {
        this.updateCellStyles(col, row, cell);
      },
    });
    this.tableHeaders = this.tableRef.getHeaders().split(',');
    this.generateProfileNames();
    this.generateCellVariableMapper();
    let profiles = this.generateProfiles();
    this.rowCount = header.length + profiles.length + this.pricingInput.length;
    this.generatePricingInputMapper();
    profiles = this.generateProfiles();
    let body = header.concat(profiles);
    if (this.pricingInput.length) {
      body = body.concat(this.pricingInput);
    }
    this.tableRef.setData(body);
    this.dataLoaded.emit(true);
  }

  remoteSearch(text: string, cb): void {
    if (text) {
      cb(
        this.cellVariableMapper
          .filter((cell) => cell.variableName.indexOf(text.toUpperCase()) === 0)
          .slice(0, 50)
      );
    } else {
      cb(this.cellVariableMapper.slice(0, 50));
    }
  }

  initCustomFormulas(): void {
    window['SUMPRODUCT'] = function () {
      let result = 0;
      if (Array.isArray(arguments[0])) {
        if (arguments.length === 1) {
          let result = 0;
          arguments[0].forEach((value) => {
            result += value;
          });
          return +result.toFixed(4);
        }
        for (let i = 0; i < arguments[0].length; i++) {
          let multiply = 1;
          for (let j = 0; j < arguments.length; j++) {
            const value = arguments[j][i];
            multiply = multiply * value;
          }
          result += multiply;
        }
        return +result.toFixed(4);
      } else {
        return '#VALUE!';
      }
    };
  }

  generateProfileNames(): void {
    const profileNames = [];
    const profileNamesFilter = [
      'Name',
      'Description',
      'Type',
      'Code',
      'IsBodyIncluded',
      'IsCalculated',
      'TakeRateEstimation',
      'DiscountedPriceWithCoefficient',
      'SinglePackagePrice',
      'LineDiscountedPrice',
      'DiscountedPrice',
      'PackageId',
      'LineId',
      'Id',
      '_Id',
      'CreateDate',
      'UpdateDate',
      'isDeleted',
    ];
    const firstCostLine = this.data.CostLineLines[0];
    Object.keys(firstCostLine).forEach((key) => {
      if (
        key.indexOf('FormulaValue') > -1 ||
        profileNamesFilter.indexOf(key) > -1
      ) {
        return false;
      }
      profileNames.push(key);
    });
    this.profileNames = profileNames;
  }

  generateProfiles(): any[] {
    const profiles = this.profileNames.map((profile, i) => {
      if (
        (profile.toLowerCase().indexOf('percent') > -1 ||
          this.percentRowNames.indexOf(profile) > -1) &&
        this.percentRows.indexOf(i + 10) === -1
      ) {
        this.percentRows.push(i + 10);
      }
      const row = [profile].concat(
        this.data.CostLineLines.map((line, j) => {
          let formulaValue = line['FormulaValue' + profile];
          if (formulaValue && formulaValue[0] === '=') {
            const value = formulaValue.replace(/\s/g, '').toUpperCase();
            this.newValueMapper[this.tableHeaders[j + 1] + (i + 11)] = value;
            formulaValue = this.variableToCellName(value);
            return formulaValue;
          } else {
            return line[profile];
          }
        })
      );
      return row;
    });
    return profiles;
  }

  generateSinglePackagePrices(): any[] {
    const singlePackagePrices = this.data.CostLineLines.map(
      (line) => line.SinglePackagePrice
    );
    return singlePackagePrices;
  }

  generateLineDiscountedPrices(): any[] {
    const lineDiscountedPrices = this.data.CostLineLines.map(
      (line) => line.LineDiscountedPrice
    );
    return lineDiscountedPrices;
  }

  generateTakeRates(): any[] {
    const takeRates = this.data.CostLineLines.map((line) => {
      if (
        line.TakeRateEstimation &&
        !isNaN(line.TakeRateEstimation as any) &&
        parseFloat(line.TakeRateEstimation) > 0
      ) {
        return (parseFloat(line.TakeRateEstimation) / 100).toString();
      }
      return 0;
    });
    return [...takeRates];
  }

  generateDiscountedPricesWithCoefficient(): any[] {
    const discountedPricesWithCoefficient = this.data.CostLineLines.map(
      (line) =>
        line.DiscountedPriceWithCoefficient
          ? +line.DiscountedPriceWithCoefficient
          : ''
    );
    return discountedPricesWithCoefficient;
  }

  generateLineNames(): any[] {
    const lineNames = this.data.CostLineLines.map((line) =>
      line.Name.replace(/\s/g, '')
    );
    return lineNames;
  }

  generateFirstRow(lineNamesLength: number): any[] {
    const firstRow = [
      'Body',
      this.data.BodyTypeCode,
      '',
      this.data.EffectiveEuroTL,
      'Effective €/TL',
    ];
    const firstRowLength = firstRow.length;
    const limitLength =
      this.pricingInput.length && this.pricingInput[1].length > lineNamesLength
        ? this.pricingInput[1].length
        : lineNamesLength;
    for (let i = 0; i < limitLength + 2 - firstRowLength; i++) {
      firstRow.push('');
    }
    return firstRow;
  }

  generatePricingInput(): any[] {
    const pricingInput = [[''], ['Pricing Input']];
    const pricingInputFilter = [
      'Id',
      '_Id',
      'Version',
      'CreateDate',
      'UpdateDate',
      'isDeleted',
      'MakeCode',
      'SeriesCode',
      'ModelCode',
      'BodyTypeCode',
      'ModelDescription',
      'UpdateLog',
    ];
    Object.keys(this.data.pricinginput).forEach((key) => {
      if (pricingInputFilter.indexOf(key) > -1) {
        return false;
      }
      pricingInput[0].push(key);
      pricingInput[1].push(this.data.pricinginput[key]);
    });
    return [[''], pricingInput[0], pricingInput[1], [''], ['']];
  }

  updateCellStyles(col: number, row: number, cell: HTMLElement): void {
    // Disable last column
    if (col === this.firstRow.length - 1) {
      cell.classList.add('readonly');
    }
    // Profiles column
    if (col === 0 && row > 7) {
      cell.classList.add('readonly');
      cell.classList.add('package-cell');
    }
    // Takerates row
    if (row === 8) {
      cell.classList.add('readonly');
      cell.classList.add('take-rate-cell');
    }
    // Single package price, discounted price, discounted price with coefficient rows
    if (row === 5 || row === 6 || row === 7) {
      cell.classList.add('readonly');
      cell.classList.add('price-cell');
    }
    // Lines row
    if (row === 9) {
      cell.classList.add('readonly');
      cell.classList.add('line-cell');
    }
    // Body table
    if (col === 0 && row < 4) {
      cell.classList.add('readonly');
      cell.classList.add('calculation-border-left');
      cell.classList.add('calculation-background');
    }
    if (col === 1 && row < 4) {
      cell.classList.add('calculation-border-right');
    }
    if (col === 1 && row < 3) {
      cell.classList.add('readonly');
      cell.classList.add('readonly-colored');
    }
    if (row === 3 && col < 2) {
      cell.classList.add('calculation-border-bottom');
    }
    if (row === 0 && col < 2) {
      cell.classList.add('calculation-border-top');
    }
    // €/TL table
    if (col === 3 && row < 3) {
      cell.classList.add('calculation-border-left');
    }
    if (col === 4 && row < 3) {
      cell.classList.add('readonly');
      cell.classList.add('calculation-border-right');
      cell.classList.add('calculation-background');
    }
    if (row === 2 && col > 2 && col < 5) {
      cell.classList.add('calculation-border-bottom');
    }
    if (row === 0 && col > 2 && col < 5) {
      cell.classList.add('calculation-border-top');
    }
    // Empty row after body table
    if (row === 4) {
      cell.classList.add('readonly');
    }
    // Disable remaining empty cells
    if (row === 3 && col > 2) {
      cell.classList.add('readonly');
    }
    if (col === 2 && row < 4) {
      cell.classList.add('readonly');
    }
    if (row < 3 && col > 4) {
      cell.classList.add('readonly');
    }
    // Disable pricing input cells
    if (this.pricingInput.length && row > this.rowCount - 6) {
      cell.classList.add('readonly');
      cell.classList.add('readonly-colored');
    }
    // Pricing input values style
    if (this.pricingInput.length && row === this.rowCount - 4) {
      cell.classList.add('line-cell');
    }
  }

  generateSaveModel(): any {
    const tableData = this.tableRef.getData();
    this.data.CC = tableData[3][1];
    this.data.EffectiveEuroTL = tableData[0][3];
    this.data.FixedEuroTL = tableData[1][3];
    this.data.MarketEuroTL = tableData[2][3];
    const lineNames = tableData[9];
    for (let rowIndex = 10; rowIndex < tableData.length; rowIndex++) {
      const row = tableData[rowIndex];
      const profileName = row[0];
      for (let colIndex = 1; colIndex < row.length - 1; colIndex++) {
        const cellName = this.tableHeaders[+colIndex] + (+rowIndex + 1);
        const col = row[colIndex];
        const lineName = lineNames[colIndex];
        let newValue = col;
        const relatedLine = this.data.CostLineLines.find(
          (line) => line.Name.replace(/\s/g, '') === lineName
        );
        if (relatedLine) {
          delete relatedLine.Id;
          delete relatedLine._Id;
          delete relatedLine.UpdateDate;
          delete relatedLine.CreateDate;
          if (newValue && newValue[0] === '=') {
            relatedLine[profileName] = this.trimCellValue(
              this.tableRef.getValue(cellName, true).toString(),
              rowIndex
            );
            // relatedLine['FormulaValue' + profileName] = this.cellNameToVariable(newValue);
            relatedLine['FormulaValue' + profileName] = this.newValueMapper[
              cellName
            ]
              ? this.newValueMapper[cellName]
              : this.cellNameToVariable(newValue);
          } else {
            newValue = newValue || '0';
            relatedLine[profileName] =
              this.percentRows.indexOf(rowIndex) > -1
                ? newValue
                : this.trimCellValue(newValue, rowIndex);
            relatedLine['FormulaValue' + profileName] = null;
          }
        }
      }
    }
    delete this.data.Id;
    delete this.data._Id;
    delete this.data.UpdateDate;
    delete this.data.CreateDate;
    delete this.data.pricinginput.Id;
    delete this.data.pricinginput._Id;
    delete this.data.pricinginput.UpdateDate;
    delete this.data.pricinginput.CreateDate;
    return this.data;
  }

  trimCellValue(value: string, rowIndex: number): string {
    if (!value) {
      return value;
    }
    value = value.replace(/,/gi, '');
    if (value[value.length - 1] === '%') {
      value = value.slice(0, -1);
      value = (rowIndex === 8 ? value : parseFloat(value) / 100).toString();
    }
    return value;
  }

  saveFormula(): void {
    let value: any = this.selectedCell.newValue.toUpperCase();
    this.selectedCell.value = value;
    if (value && value[0] === '=') {
      this.newValueMapper[this.selectedCell.name] = value;
      value = this.variableToCellName(value);
    } else {
      delete this.newValueMapper[this.selectedCell.name];
    }
    this.tableRef.setValue(this.selectedCell.name, value);
  }

  variableToCellName(value: any): string {
    value = value.toUpperCase();
    const pattern = /\[[^\]]*\]/g;
    const matches = value.match(pattern);
    if (matches && matches.length) {
      matches.forEach((match) => {
        const matchMap = this.cellVariableMapper.find(
          (c) => '[' + c.variableName + ']' === match
        );
        if (matchMap) {
          value = value.replaceAll(match, matchMap.cellName);
        }
      });
    }
    return value.replace(/\s/g, '');
  }

  cellNameToVariable(value: any): string {
    for (let i = this.tableHeaders.length - 1; i > 0; i--) {
      const letter = this.tableHeaders[i];
      for (let i = this.profileNames.length + 10; i > 5; i--) {
        if (['B4', 'D1', 'D2', 'D3'].indexOf(letter + i) === -1) {
          const related = this.cellVariableMapper.find(
            (variable) => variable.cellName === letter + i
          );
          if (related) {
            let searching = true;
            let startIndex = 0;
            while (searching) {
              const indexOfCellName = value.indexOf(
                related.cellName,
                startIndex
              );
              if (indexOfCellName > -1) {
                if (
                  [
                    '<',
                    '>',
                    '=',
                    '+',
                    '-',
                    '*',
                    '/',
                    ':',
                    ';',
                    '(',
                    ',',
                    ' ',
                    '',
                  ].indexOf(value[indexOfCellName - 1]) > -1
                ) {
                  value =
                    value.substring(0, indexOfCellName) +
                    '[' +
                    related.variableName +
                    ']' +
                    value.substring(indexOfCellName + related.cellName.length);
                }
                startIndex = indexOfCellName + related.cellName.length;
              } else {
                searching = false;
              }
            }
            // value = value.replaceAll(related.cellName, '[' + related.variableName + ']')
          }
        }
      }
    }
    ['B4', 'D1', 'D2', 'D3']
      .concat(this.pricingInputCellNames)
      .forEach((customCell) => {
        if (value.indexOf(customCell) > -1) {
          const related = this.cellVariableMapper.find(
            (variable) => variable.cellName === customCell
          );
          if (related) {
            let searching = true;
            let startIndex = 0;
            while (searching) {
              const indexOfCellName = value.indexOf(
                related.cellName,
                startIndex
              );
              if (indexOfCellName > -1) {
                if (
                  ['=', '+', '-', '*', '/', ':', '(', ',', ' ', ''].indexOf(
                    value[indexOfCellName - 1]
                  ) > -1
                ) {
                  value =
                    value.substring(0, indexOfCellName) +
                    '[' +
                    related.variableName +
                    ']' +
                    value.substring(indexOfCellName + related.cellName.length);
                }
                startIndex = indexOfCellName + related.cellName.length;
              } else {
                searching = false;
              }
            }
            // value = value.replaceAll(customCell, '[' + related.variableName + ']')
          }
        }
      });
    return value;
  }

  generateCellVariableMapper(): void {
    this.cellVariableMapper = [
      {
        variableName: 'CC',
        cellName: 'B4',
      },
      {
        variableName: 'EFFECTIVEEUROTL',
        cellName: 'D1',
      },
      {
        variableName: 'FIXEDEUROTL',
        cellName: 'D2',
      },
      {
        variableName: 'MARKETEUROTL',
        cellName: 'D3',
      },
    ];
    this.lineNames
      .map((line) => line.toUpperCase())
      .forEach((line, lineIndex) => {
        this.cellVariableMapper.push(
          {
            variableName: line + '.' + 'SINGLEPACKAGEPRICE',
            cellName: this.tableHeaders[lineIndex + 1] + 6,
          },
          {
            variableName: line + '.' + 'LINEDISCOUNTEDPRICE',
            cellName: this.tableHeaders[lineIndex + 1] + 7,
          },
          {
            variableName: line + '.' + 'DISCOUNTEDPRICEWITHCOEFFICIENT',
            cellName: this.tableHeaders[lineIndex + 1] + 8,
          },
          {
            variableName: line + '.' + 'TAKERATEESTIMATION',
            cellName: this.tableHeaders[lineIndex + 1] + 9,
          }
        );
        this.profileNames
          .map((profile) => profile.toUpperCase())
          .forEach((profile, profileIndex) => {
            this.cellVariableMapper.push({
              variableName: line + '.' + profile,
              cellName: this.tableHeaders[lineIndex + 1] + (profileIndex + 11),
            });
          });
      });
  }

  generatePricingInputMapper(): void {
    if (this.pricingInput.length) {
      this.pricingInput[1].slice(1).forEach((key, keyIndex) => {
        const cellName = this.tableHeaders[keyIndex + 1] + (this.rowCount - 2);
        this.cellVariableMapper.push({
          variableName: ('PRICINGINPUT' + '.' + key).toUpperCase(),
          cellName,
        });
        this.pricingInputCellNames.push(cellName);
      });
    }
  }

  initTribute(input: HTMLInputElement): void {
    const tributeRef = document.querySelector('.tribute-container');
    if (tributeRef) {
      tributeRef.parentNode.removeChild(tributeRef);
    }
    if (input) {
      const tribute = new Tribute({
        trigger: '#',
        selectTemplate: (item) => {
          return '[' + item.original['variableName'];
        },
        values: (text, cb) => {
          this.remoteSearch(text, (users) => cb(users));
        },
        lookup: 'variableName',
        fillAttr: 'variableName',
        allowSpaces: false,
        requireLeadingSpace: false,
        noMatchTemplate: () => {
          return '<span style:"visibility: hidden;"></span>';
        },
        replaceTextSuffix: ']',
      });
      tribute.attach(input);
    }
  }

  maskCellValue(cell: HTMLElement, rowIndex: number): void {
    let innerText: any = cell.innerText
      ? cell.innerText.replace(/,/gi, '')
      : cell.innerText;
    if (innerText && isNaN(innerText as any)) {
      return;
    }
    innerText = +innerText;
    const colIndex = +cell.getAttribute('data-x');
    if (this.percentRows.indexOf(rowIndex) > -1) {
      if (innerText[innerText.length - 1] !== '%' && rowIndex !== 8) {
        cell.innerText =
          (+(innerText * (rowIndex === 8 ? 1 : 100)).toFixed(2)).toLocaleString(
            'en-US'
          ) + '%';
      }
    } else {
      if (
        rowIndex === this.rowCount - 3 ||
        this.noMaskCells.some(
          (cell) => cell.x === colIndex && cell.y === rowIndex
        )
      ) {
        cell.innerText = innerText.toLocaleString('en-US', {
          maximumSignificantDigits: 20,
        });
      } else {
        cell.innerText = (+innerText.toFixed(0)).toLocaleString('en-US');
      }
    }
  }

  maskOnLoad(): void {
    const rows = document.querySelectorAll('.jexcel tr[data-y]');
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i] as HTMLElement;
      const rowIndex = +row.getAttribute('data-y');
      const cells = Array.from(row.querySelectorAll('td[data-x]')).filter(
        (cell: any) => {
          const colIndex = +cell.getAttribute('data-x');
          const innerText = cell.innerText;
          return (
            colIndex > 0 &&
            innerText &&
            innerText.length &&
            !isNaN(innerText as any)
          );
        }
      );
      cells.forEach((cell: any) => {
        this.maskCellValue(cell, rowIndex);
      });
    }
  }
}
