import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../shared/smartadmin.config';
import { catchError, delay, Observable, throwError, map } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ErrorLogService {
  private access_token: any;
  constructor(private http: HttpClient) {}

  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + config.API_URL + url).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  public insertError(error_info: any): Observable<any> {
    let body = JSON.stringify(error_info);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(config.API_ENDPOINT + 'error/save', body);
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
