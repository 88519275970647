<div class="slider">
  <div class="slide" >


    <img src="../../../assets/images/slider/1.png" alt="Image 1" class="img-fluid" style="border-color: #8186d5;" [border]="10">
    <img src="../../../assets/images/slider/9.png" alt="Image 2" class="img-fluid" style="border-color: #8186d5;" [border]="10">
    <img src="../../../assets/images/slider/8.png" alt="Image 3"class="img-fluid" style="border-color: #8186d5;" [border]="10">
    <img src="../../../assets/images/slider/12.png" alt="Image 4"class="img-fluid" style="border-color: #8186d5;" [border]="10">
    <img src="../../../assets/images/slider/7.png" alt="Image 5"class="img-fluid" style="border-color: #8186d5;;" [border]="10">
    <img src="../../../assets/images/slider/10.png" alt="Image 6"class="img-fluid" style="border-color: #8186d5;" [border]="10">

  </div>


  <!-- Diğer slayt elemanları -->
</div>
