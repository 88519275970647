import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './auth/login/login.component';
import { LockedComponent } from './auth/locked/locked.component';
import { NgChartsModule } from 'ng2-charts';
import { SwiperComponent } from './swiper/swiper.component';
import { SliderComponent } from './slider/slider.component';
import { RouterModule } from '@angular/router';
import { MailComponent } from './auth/mail/mail.component';


@NgModule({
  declarations: [
    LoginComponent,
    LandingPageComponent,
    DashboardComponent,
    ErrorPageComponent,
    NotFoundPageComponent,
    LockedComponent,
    SwiperComponent,
    SliderComponent,
    MailComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule,NgChartsModule, RouterModule],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    SwiperComponent,
    SliderComponent,
    MailComponent

  ]
})
export class PagesModule {}
