<body class="header-sticky header-over" data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="71">

  <div id="wrapper">





    <!-- Header-->
    <header id="header" class="shadow-xs">

      <nav appNavbarScroll class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light" id="navbar">
        <div class="container-fluid">

          <div class="navbar-brand logo text-uppercase" href="index-1.html" *ngIf="!isMobile">
            <div class="logo-container" style="max-width: 300px; max-height: 100px;">
              <img src="../../../assets/configease.png" class="logo-light" alt="Light Logo" style="width: 100%; height: 100%;">
              <img src="../../../assets/configease.png" class="logo-dark" alt="Dark Logo" style="width: 100%; height: 100%;">
            </div>
          </div>
          <div class="navbar-brand logo text-uppercase" href="index-1.html" *ngIf="isMobile">
            <div class="logo-container" style="max-width: 230px; max-height: 150px;">
              <img src="../../../assets/configease.png" class="logo-light" alt="Light Logo" style="width: 100%; height: 100%;">
              <img src="../../../assets/configease.png" class="logo-dark" alt="Dark Logo" style="width: 100%; height: 100%;">
            </div>
          </div>


          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ms-auto" id="navbar-navlist" *ngIf="!isMobile">
                  <li class="nav-item">
                      <a class="nav-link" href="#home">Home</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="#about">About</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link" href="#features">FeaTures</a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" href="#app">IMAGES
                    </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#reference">REFERENCES
                  </a>
              </li>


              </ul>


              <div class="ms-auto" *ngIf="!isMobile">
                <a href="#contact" aria-label="Account Demo Request" class="btn bg-gradiant">
                  <span class="group-icon">
                    <i class="fi fi-link"></i>
                    <i class="fi fi-close"></i>
                  </span>
                  <span>REQUEST DEMO</span>
                </a>
              <!-- <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active" aria-label="Account Sign In"
                  class="btn bg-gradiant">
                  <span class="group-icon">
                    <i class="fi fi-user-male"></i>
                    <i class="fi fi-close"></i>
                  </span>
                  <span>SIGN IN</span>
                </a>-->


              </div>


              <ul class="navbar-nav ms-auto" id="navbar-navlist" *ngIf="isMobile">
                <img src="../../../assets/configease.png" class="logo-dark" alt="" height="90" width="auto">
                <br>
                <li class="nav-item">
                    <a class="nav-link" href="index.html">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="index.html">About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="index.html">FeaTures</a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="index.html">IMAGES
                  </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="index.html">REFERENCES
                </a>
            </li>

              <li class="nav-item" >
                <a href="index.html" class="nav-link">
              REQUEST DEMO
                </a>
              </li>
          <!---  <li class="nav-item" >
                <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active" aria-label="Account Sign In"
                class="nav-link">

           SIGN IN
              </a>
              </li>-->



            </ul>
            <a href="index.html">
              <button class="navbar-toggler pt-0" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarMainNav" aria-controls="navbarMainNav" aria-expanded="false"
              aria-label="Toggle navigation" style="text-align: right;">
              <svg width="20" viewBox="0 0 20 20">
                <path
                  d="M 20.7895 0.977 L 19.3752 -0.4364 L 10.081 8.8522 L 0.7869 -0.4364 L -0.6274 0.977 L 8.6668 10.2656 L -0.6274 19.5542 L 0.7869 20.9676 L 10.081 11.679 L 19.3752 20.9676 L 20.7895 19.5542 L 11.4953 10.2656 L 20.7895 0.977 Z">
                </path>
              </svg>
            </button>
</a>




          </div>

      </div>
  </nav>


    </header>
    <!-- /Header -->

    <div class="overflow-hidden-x">
      <!-- home section -->
      <section class="home-2 home-slider" id="home">
          <div class="box">
              <div class="wave -one"></div>
              <div class="wave -two"></div>
              <div class="wave -three"></div>
          </div>
          <div class="container">
              <div class="row align-items-center justify-content-between">
                  <div class="col-md-6">
                      <div class="home-heading">
                        <a href="#contact">
                          <span class="badge bg-soft-primary rounded-pill mb-3">Request a demo now</span></a>
                          <h5>Welcome to REA Technology's Configease

</h5> <h4>Revolutionizing Automotive Production!
</h4>
                          <p class="home-title"></p>
                          <p class="text-muted">Are you ready to streamline your vehicle production process, maximize efficiency, and enhance market competitiveness? Discover the power of Configease, our cutting-edge Configure, Price, Quote Application.</p>
                      </div>
                  </div>
                  <div class="col-md-6">
                     <app-swiper></app-swiper>
                  </div>
              </div>
          </div>

      </section>
      <!-- end home section -->

      <div class="container-fluid">
          <div class="row">
              <div class="mobile">
                  <a href="#about">
                      <span class="phone">
                          <i class="mdi mdi-cellphone"></i>
                      </span>
                  </a>
              </div>
          </div>
          <!--end row-->
      </div>

      <!-- start about us -->
      <section class="section about bg-light" id="about">
       <!--   <div class="container">
              <div class="row text-center">
                  <div class="col-lg-12">
                      <div class="mockup mockup-macbook loaded opened">
                          <div class="part top">
                              <img src="../../../assets/images/about/macbook-top.svg" alt="" class="img-fluid top">
                              <img src="../../../assets/images/about/macbook-cover.svg" alt="" class="img-fluid cover">
                              <video #myVideo autoplay muted controls loop>
                                <source src="../../../assets/CPQ  Tanıtım Videosu.mp4" type="video/mp4">
                              </video>

                          </div>
                          <div class="part bottom">
                              <img src="../../../assets/images/about/macbook-cover.svg" alt="" class="img-fluid cover">
                              <img src="../../../assets/images/about/macbook-bottom.svg" alt="" class="img-fluid bottom">
                          </div>
                      </div>
                  </div>
              </div>
          </div>-->
          <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="sec-hd">
                        <span class="heading"></span>
                        <h2 class="sec-title">About</h2>
                        <span class="heading"></span>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-6">
<!--style="margin-bottom: 200px;"-->
                      <h4 class="my-4" >Effortless Precision</h4>
                        <p class="text-muted">
                          Create accurate and competitive packages effortlessly, with dynamic pricing analysis for strategic market placement. Seamlessly adapt to market trends for sustained success.
                          <h4 class="my-4">Innovative Solutions, Trusted Partnership
                          </h4>
                          <p class="text-muted">
                            Benefit from Configease's 3-year research-backed adaptability; customize it with our tech experts. Trust REA Technology, a 5-year technology partner for 20+ leading companies in the USA, UK, and Turkey.


                            </p>

                </div>

              <!---      <div class="pdf-container">
                    <a href="../../../assets/4053 2.pdf" download="sunum.pdf" class="custom-download-btn">&nbsp;Dowloand</a>
                  <iframe src="../../../assets/4053 2.pdf" frameborder="5"></iframe></div>
      <img src="../../../assets/sunum.png" style="width: 300px; height: auto; text-align: center;">
            <app-images></app-images>-->

            <div class="col-lg-6" style="text-align: center;">
              <a href="../../../assets/configease.pdf" target="_blank">
                <img src="../../../assets/configeasyslayt.png" style="width: 100%; max-width: 400px; height: auto; border-radius: 10px;">
              </a>
            </div>




          </div>
        </div>
        </section>
      <!-- end about us -->
<!--
      <section class="section about bg-light" id="about">
          <div class="container">
               <div class="row text-center">
                   <div class="col-lg-12">
                    <div>
                      <img id="resim" src="../../../assets/SMArtPRıcıng.png" alt="Video Oynatma" style="width:800px;height:500px;cursor:pointer;" (click)="oynatVideo()">
                      <video id="video" width="800" height="500" controls style="display:none;">
                        <source src="../../../assets/CPQ  Tanıtım Videosu.mp4" type="video/mp4">
                        Tarayıcınız video etiketini desteklemiyor.
                      </video>
                    </div>

                   </div>
               </div>
           </div>
           </section>-->


<!-- Features section -->
<section class="section features" id="features">
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-lg-12">
              <div class="sec-hd">
                  <span class="heading"></span>
                  <h2 class="sec-title">Features</h2>
                  <span class="heading"></span>
              </div>
          </div>
      </div>
      <div class="row justify-content-center align-items-center">
          <div class="col-lg-4 col-md-10">
              <div class="features-box">

                  <div>

                      <h5 class="f-15 text-uppercase"> Smart Production Optimization</h5>
                      <p class="text-muted mt-3">  Ensure vehicles are produced with the utmost affordability and optimal features. Expedite production time.</p>
                  </div>
              </div>

              <div class="features-box mt-5">

                  <div>
                      <h5 class="f-15 text-uppercase">Efficient Demand Management</h5>
                      <p class="text-muted mt-3">Accurately capture end-user demands for vehicle equipment. Meet production needs at the lowest possible cost.</p>
                  </div>
              </div>

              <div class="features-box mt-5">

                  <div>
                      <h5 class="f-15 text-uppercase">Streamlined File Transfer</h5>
                      <p class="text-muted mt-3">Transfer and manage product files within minutes. Eliminate the exhaustive operational load.</p>
                  </div>
              </div>
          </div>

          <div class="col-lg-4 col-md-10">
              <div class="text-center">
                  <img src="../../../assets/images/various/configeasy.png" class="img-fluid" alt="">
              </div>
          </div>

          <div class="col-lg-4 col-md-10">
              <div class="features-box">

                  <div>
                      <h5 class="f-15 text-uppercase">Collaboration and Communication</h5>
                      <p class="text-muted mt-3">Foster effective communication and collaboration among production teams, suppliers.</p>
                  </div>
              </div>

              <div class="features-box mt-5">

                  <div>
                      <h5 class="f-15 text-uppercase">Flexible Rule Management</h5>
                      <p class="text-muted mt-3">Intelligently examine and transfer product files with a unique algorithm. Empower users to change.</p>
                  </div>
              </div>

              <div class="features-box mt-5">

                  <div>
                      <h5 class="f-15 text-uppercase">Dynamic Market Analysis</h5>
                      <p class="text-muted mt-3">Leverage Price Overview Screens for in-depth market analysis. Play with countless model versions.</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<!-- end section -->


      <!-- start work
      <section class="section work bg-light" id="howitswork">
        <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="sec-hd">
                      <span class="heading"></span>
                      <h2 class="sec-title">How It's work</h2>
                      <span class="heading"></span>
                  </div>
              </div>
          </div>
          <div class="row align-items-center">
              <div class="col-lg-6">

                    <h4 class="my-4" >60% Faster Business</h4>
                      <p class="text-muted">
                        Answering those questions and more is a tall order, but one you should approach with enthusiasm. After all, this is the one place you can and should sing your own praises.
                        <h4 class="my-4">Control & Protection
                        </h4>
                        <p class="text-muted">
                          Answering those questions and more is a tall order, but one you should approach with enthusiasm. After all, this is the one place you can and should sing your own praises.


                          </p>


              </div>

                  <div class="col-lg-6">

                    <div class="pdf-container">
                      <a href="../../../assets/4053 2.pdf" download="sunum.pdf" class="custom-download-btn">&nbsp;Download</a>
                      <embed src="../../../assets/4053 2.pdf" type="application/pdf"  />
                    </div>



                  </div>




              </div>
          </div>
      </section>
      end work -->

      <!-- slider section -->
      <section class="section app-slider" id="app">
          <!-- start container -->
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-lg-12">
                      <div class="sec-hd">
                          <span class="heading"></span>
                          <h2 class="sec-title">Configease Images</h2>
                          <span class="heading"></span>
                      </div>
                  </div>
                  <!--end col-->
                  <app-slider></app-slider>
              </div>


              <!-- end container -->
          </div>
      </section>




      <!-- end section -->


      <!-- cta section --><!--
      <section class="section cta bg-light" id="cta">
     yorumda//   start container
          <div class="container position-relative">
              <div class="row">
                  <div class="col-xl-6 col-lg-10">
                      <div class="py-5">
                          <h1 class="display-4">Build Your ideal workspace today.</h1>
                          <p class="text-muted mt-3 f-18">Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                              Iure distinctio vero facilis numquam sapiente! Eaque inventore eveniet repellendus quod
                              maiores nulla.</p>
                          <div class="d-flex mt-4 ">
                              <div class="app-store">
                                  <a href="javascript: void(0);"><img src="images/img-appstore.png" alt=""
                                          class="img-fluid"></a>
                              </div>
                              <div class="googleplay">
                                  <a href="javascript: void(0);"><img src="images/img-googleplay.png " alt=""
                                          class="img-fluid ms-3"></a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="cta-phone-image">
                        //  yorumda <img src="images/home/home-4.png" alt="" class=" img-fluid">
                      </div>
                  </div>
              </div>
          </div>

      </section>-->
      <!-- end section -->











      <section class="section about bg-light" id="reference">
        <div class="container text-center">
          <div class="row">
            <div class="col-lg-12">
              <div class="sec-hd">
                <span class="heading"></span>
                <h2 class="sec-title">References</h2>
                <span class="heading"></span>
              </div>
            </div>
          </div>

        <div class="partner-grid">
            <div class="partner-card" style="text-align: right;">
                <img src="../../../assets/bmw.png" style="width: 100%; max-width: 100px; height: auto; margin: 0 auto; text-align: right;" class="card-img-top" alt="Symanto Logo">
                <h3></h3>
                <p>BMW TURKEY</p>
            </div>

            <div class="partner-card" style="text-align: left;">
                <img src="../../../assets/mini.png" alt="Btrisk Logo" style="width: 100%; max-width: 170px; height: auto; margin: 0 auto; text-align: left;" class="card-img-top">
                <h3></h3>
                <p style="padding-left: 30px;">MINI TURKEY</p>
            </div>


        </div>
        </div>
    </section>











      <!-- contact section -->
      <section class="contact overflow-hidden" id="contact">
          <!-- start container -->
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-lg-12">
                      <div class="sec-hd">
                          <span class="heading"></span>
                          <h2 class="sec-title">Request Demo</h2>
                          <span class="heading"></span>
                      </div>
                  </div>
              </div>
              <div class="row align-items-center justify-content-center">
                  <div class="col-lg-8">
                      <div class="contact-box">
                          <div class="custom-form mt-4">
                            <form [formGroup]="form" (ngSubmit)="send()" class="email-form">
                                  <p id="error-msg" style="opacity: 1;"> </p>
                                  <div id="simple-msg"></div>
                                  <div class="row">


                                      <div class="col-lg-12">
                                          <div class="form-group">
                                              <input  formControlName="from_name" id="from_name"  type="text"
                                                  class="form-control contact-form" placeholder="Name">
                                          </div>
                                      </div>

                                      <div class="col-lg-12">
                                        <div class="form-group">
                                            <input  formControlName="from_surname" id="from_surname"  type="text"
                                                class="form-control contact-form" placeholder="Surname"/>
                                        </div>
                                    </div>
                                      <div class="col-lg-12">
                                          <div class="form-group">
                                              <input formControlName="from_email" id="from_email" type="email"
                                                  class="form-control contact-form" placeholder="Email">
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" class="form-control contact-form" formControlName="from_number" id="from_number"
                                                placeholder="Phone">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-12">
                                      <div class="form-group">
                                          <input type="text" class="form-control contact-form" formControlName="from_job" id="from_job"
                                              placeholder="Job Title">
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control contact-form" formControlName="from_com" id="from_com"
                                            placeholder="Company Name">
                                    </div>
                                </div>
                            </div>
                                  <div class="row">
                                      <div class="col-lg-12">
                                          <div class="form-group">
                                              <textarea type="text" class="form-control contact-form" formControlName="from_detail" id="from_detail"
                                                  placeholder="Detail"> </textarea>
                                          </div>
                                      </div>
                                  </div>



                                  <div class="row justify-content-center">
                                      <div class="col-lg-6">
                                          <div class="text-center">
                                            <button class="submitBnt btn btn-rounded bg-gradiant" type="submit">Send Message</button>

                                          </div>
                                      </div>
                                  </div>
                              </form>
                          </div>



                      </div>
                  </div>
              </div>


          </div>


          <!-- end container -->
      </section>
      <!-- end section -->
  </div>

  <hr>








 <!--
  <section class="section cta bg-light" id="cta">
    <div class="container position-relative">
      <div class="row">
        <div class="col-xl-6 col-lg-10">
          <div class="py-5">
            <h1 class="display-4">Reference</h1>
            <p class="text-muted mt-3 f-18">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iure distinctio vero
              facilis numquam sapiente! Eaque inventore eveniet repellendus quod maiores nulla.</p>
            <div class="d-flex mt-4 align-items-center">
              <div class="app-store">
                <a href="javascript: void(0);">
                  <img src="../../../assets/bmw.png" alt="" class="img-fluid">
                </a>
              </div>
              <div class="googleplay ms-3">
                <a href="javascript: void(0);">
                  <img src="../../../assets/mini.png" alt="" class="img-fluid">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
         Ekstra içerik ekleyebilirsiniz
        </div>
      </div>
    </div>
  </section>-->



















































  <!-- end footer --><br> <br> <br><br>


  <!-- Footer -->
  <footer class="footer-20192">
    <div class="site-section">
      <div class="container">

        <div class="cta d-block d-md-flex align-items-center px-5" style="text-align: center;">
          <div >
            <h2 class="mb-0">Why Should We Use Configease?</h2>
            <h3 class="text-dark"> Submit a demo request to learn now!</h3>
          </div>

        </div>

        <div class="row">
          <div class="col-sm-3">
            <a href="https://www.reateknoloji.com/" class="footer-logo">
            <img src="../../../assets/rea-white.png" height="60"></a>
            <br>
            <br>
            <p class="copyright">
              We are an innovative company working with passion and love. We provide the best services you need and help you achieve your goal.
            </p>
          </div>

          <div class="col-sm-3">
            <h3>Services</h3>
            <ul class="list-unstyled links">
              <li><a href="#home">Home</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#features">Features</a></li>
              <li><a href="#app">Images</a></li>
            </ul>
          </div>

          <div class="col-sm-3">
            <h3>Useful links</h3>
            <ul class="list-unstyled links">
          <!-- <li><a [routerLink]="['/auth/login']" routerLinkActive="router-link-active"> Sign In</a></li>
-->
              <li><a href="#contact">Request Demo</a></li>
            </ul>
          </div>

          <div class="col-sm-3">
            <h3>Communication</h3>
            <ul class="list-unstyled links">
              <li><a><b>Email:</b> info@reateknoloji.com</a></li>
              <li><a><b> HEAD Office:</b> Level 39 One Canada Square, Canary Wharf, London, England, E14 5AB</a></li>
            </ul>









          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-3">
<h3></h3>
            <ul class="list-unstyled social" style="text-align: center;">
              <li><a href="#"><span class="fab fa-google"></span></a></li>
              <li><a href="#"><span class="fab fa-facebook"></span></a></li>
              <li><a href="#"><span class="fab fa-instagram"></span></a></li>
              <li><a href="https://www.linkedin.com/company/reateknoloji/mycompany/?viewAsMember=true"><span class="fab fa-linkedin"></span></a></li>
              <li><a href="#"><span class="fab fa-github"></span></a></li>
            </ul>
          </div>
        </div>

      </div>
    </div>
    <div class="text-center p-4">
      © Copyright:
      <a class="text-reset fw-bold" href="https://www.reateknoloji.com/">Rea Technology</a>
    </div>
  </footer>
  <!-- Footer -->

