import { Injectable } from '@angular/core';
import * as alertify from 'alertifyjs';

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

constructor() { }


  setPosition(){
    alertify.set('notifier','position', 'top-right');
  }
  
  success(message: string) {
    this.setPosition();
    alertify.success(message);
  }
  notify(message:string){
    this.setPosition();
    alertify.notify(message,'primary');
  }
  warning(message:string){
    this.setPosition();
    alertify.warning(message);
  }
  error(message: string) {
    this.setPosition();
    alertify.error(message);
  }

  // confirm(message: string, onConfirm: () => void, onCancel?: () => void) {
  //   alertify.confirm(message, onConfirm, onCancel);
  // }

  confirm(
    message: string,
    onConfirm: () => void,
    onCancel?: () => void,
    okLabel: string = 'OK',
    cancelLabel: string = 'Cancel',
    okClass: string = 'btn-primary',
    cancelClass: string = 'btn-secondary'
  ) {
    alertify.confirm(message, onConfirm, onCancel)
      .set('labels', { ok: okLabel, cancel: cancelLabel })
      .set('buttonFocus', 'cancel')
      .set('defaultFocus', 'cancel')
      .set('buttonReverse', true)
      .set('buttonClass', { ok: okClass, cancel: cancelClass });
  }
}
