import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AddFormulaComponent } from './add-formula/add-formula.component';
import { MakeCodesComponent } from './make-code/make-codes.component';
import { PriceOverviewTableComponent } from './price-overview-table/price-overview-table.component';
import { ModalComponent } from './modal/modal.component';
import { MentionModule } from 'angular-mentions';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    MentionModule
  ],
  declarations: [
    MakeCodesComponent,
    AddFormulaComponent,
    PriceOverviewTableComponent,
    ModalComponent
  ],
  exports: [
    MakeCodesComponent,
    AddFormulaComponent,
    PriceOverviewTableComponent,
    ModalComponent
  ],
})
export class SmartpricingComponentsModule {}
