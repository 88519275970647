import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import emailjs from "@emailjs/browser";

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.css']
})
export class MailComponent implements OnInit {
  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      from_name: '',
      to_name: '',
      from_email: '',
      subject: '',
      message: ''
    });
  }

  async send() {
    emailjs.init('9ahmA54Hubjg-Wq0W');
    try {
      const response = await emailjs.send('service_m1yfr25', 'template_2qe7m0f', {
        from_name: this.form.value.from_name,
        to_name: this.form.value.to_name,
        from_email: this.form.value.from_email,
        subject: this.form.value.subject,
        message: this.form.value.message
      });

      console.log('Email sent:', response);
      alert('Message has been sent');
      this.form.reset();
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email. Please try again later.');
    }
  }
}
