import { Component, OnInit } from '@angular/core';
import { INavData } from '@coreui/angular';
import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from '../../../assets/icon/icon-subset';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  constructor(private iconSetService: IconSetService) {
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  public navItems: INavData[] = [
    {
      name: 'Dashboard',
      iconComponent: { name: 'cil-home' },
      url: '/admin/',
      // children: [
      //   {
      //     name: 'Dashboard',
      //     url: '/admin/',
      //   },
      // ],
    },
    {
      name: 'Countries',
      iconComponent: { name: 'cil-layers' },
      // url: 'admin/series',
      children: [
        {
          name: 'Country List',
          url: '/admin/series',
        },
        {
          name: 'Add Country',
          url: '/admin/add-serie',
        },
      ],
    },
    // {
    //   name: 'Body Types',
    //   iconComponent: { name: 'cilSpreadsheet' },
    //   // url: '/admin/body-types',
    //   children: [
    //     {
    //       name: 'Body Type List',
    //       url: '/admin/body-types',
    //     },
    //     {
    //       name: 'Add Body Type',
    //       url: '/admin/add-body-type',
    //     },
    //   ],
    // },
    {
      name: 'Entities',
      iconComponent: { name: 'cilLineWeight' },
      // url: '/admin/models',
      children: [
        {
          name: 'Entity List',
          url: '/admin/models',
        },
        {
          name: 'Add Entity',
          url: '/admin/add-model',
        },
      ],
    },
    {
      name: 'PDL',
      iconComponent: { name: 'cil-copy' },
      // url: '/admin/product-files',
      children: [
        {
          name: 'PDL List',
          url: '/admin/product-files',
        },
        {
          name: 'PDL Upload',
          url: '/admin/uploader',
        },
      ],
    },
    {
      name: 'Feature Overview',
      iconComponent: { name: 'cil-lan' },
      url: '/admin/add-cost-overview',
      // children: [
      //   {
      //     name: 'Add Cost Overview',
      //     url: '/admin/add-cost-overview',
      //   },
      // ],
    },
    {
      name: 'Feature Usage',
      iconComponent: { name: 'cil-object-group' },
      url: '/admin/packages',
      // children: [
      //   {
      //     name: 'Packages',
      //     url: '/admin/packages',
      //   },
      // ],
    },
    {
      name: 'Config List',
      iconComponent: { name: 'cil-border-outer' },
      url: '/admin/lines',
      // children: [
      //   {
      //     name: 'Line List',
      //     url: '/admin/lines',
      //   },
      // ],
    },
    {
      name: 'Features',
      iconComponent: { name: 'cil-columns' },
      // url: '/admin/options',
      children: [
        {
          name: 'Feature List',
          url: '/admin/options',
        },
        {
          name: 'Add Feature',
          url: '/admin/add-option',
        },
        {
          name: 'Import Feature',
          url: '/admin/import-options',
        },
        {
          name: 'Feature Group List',
          url: '/admin/options-group',
        },
        {
          name: 'Import Feature Group',
          url: '/admin/import-options-group',
        },
      ],
    },
    {
      name: 'Standart Features',
      iconComponent: { name: 'cil-columns' },
      url: '/admin/add-standart-option',
      // children: [
      //   {
      //     name: 'Add Standart Option',
      //     url: '/admin/add-standart-option',
      //   },
      // ],
    },
    {
      name: 'Feature Usage Discounts',
      iconComponent: { name: 'cil-tag' },
      // url: '/admin/discount-list',
      children: [
        {
          name: 'Discount List',
          url: '/admin/discount-list',
        },
        {
          name: 'Import Discount',
          url: '/admin/import-discounts',
        },
      ],
    },
    {
      name: 'Price Overview',
      iconComponent: { name: 'cil-money' },
      // url: '/admin/priceoverview',
      children: [
        {
          name: 'Price Overview',
          url: '/admin/priceoverview',
        },
        {
          name: 'Formula Templates',
          url: '/admin/priceoverview-templates',
        },
      ],
    },
    {
      name: 'Pricing Input',
      iconComponent: { name: 'cil-credit-card' },
      // url: '/admin/pricing-input-list',
      children: [
        {
          name: 'Input List',
          url: '/admin/pricing-input-list',
        },
        {
          name: 'Import Input',
          url: '/admin/import-pricing-input',
        },
      ],
    },
    {
      name: 'SP Coefficient',
      iconComponent: { name: 'cil-object-group' },
      url: '/admin/sp-coefficient-list',
      // children: [
      //   {
      //     name: 'SP Coefficient List',
      //     url: '/admin/sp-coefficient-list',
      //   },
      // ],
    },
    {
      name: 'Sap Integration',
      iconComponent: { name: 'cil-object-group' },
      // url: '/admin/sap-integration',
      children: [
        {
          name: 'Integration List',
          url: '/admin/sap-integration',
        },
        {
          name: 'Add Integration',
          url: '/admin/list-sap-integration',
        },
      ],
    },
  ];

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  ngOnInit(): void {}

  getUsername() {
    return localStorage.getItem('username');
  }
  logOut() {
    localStorage.removeItem('username');
    localStorage.removeItem('auth_info');

  }
}
