import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../shared/smartadmin.config';
import { catchError, delay, map, Observable, throwError } from 'rxjs';

@Injectable()
export class PackageService {
  private access_token: any;
  constructor(private http: HttpClient) {}

  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + config.API_URL + url).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  public addNewPackage(packageModel: any): Observable<any> {
    let body = JSON.stringify(packageModel);
    return this.http.post(config.API_ENDPOINT + 'package/save', body);
  }

  public copyNewPackage(packageModel: any): Observable<any> {
    let body = JSON.stringify(packageModel);
    return this.http.post(config.API_ENDPOINT + 'package/copy', body);
  }

  public getAllPackages(search_model, isAddedAlternative?): Observable<any> {
    let isAddedAlternatives = isAddedAlternative ? isAddedAlternative : false;
    let body = JSON.stringify(search_model);
    return this.http.post(
      config.API_ENDPOINT +
        'package/listall?isAddedAlternative=' +
        isAddedAlternatives,
      body
    );
  }
  public getAllPackageStatistics(): Observable<any> {
    return this.http.post(
      config.API_ENDPOINT +
        'package/listall', {}
    );
  }
  public getAllPackageDiscounts(search_model): Observable<any> {
    let body = JSON.stringify(search_model);
    return this.http.post(config.API_ENDPOINT + 'discount/listversions', body);
  }

  public getAllPackagesByModelCode(model_code: any): Observable<any> {
    return this.http.get(
      config.API_ENDPOINT + 'package/findbymodel?modelcode=' + model_code
    );
  }

  public getPackageDiscountById(_id: any): Observable<any> {
    return this.http.get(config.API_ENDPOINT + 'discount/get?_id=' + _id);
  }

  public getPackageById(package_id: any): Observable<any> {
    return this.http.get(
      config.API_ENDPOINT + 'package/get?package_id=' + package_id
    );
  }

  public deletePackage(pack: any): Observable<any> {
    let body = JSON.stringify(pack);
    return this.http.post(config.API_ENDPOINT + 'package/delete', body);
  }

  public getBasePrice(option_package_model: any): Observable<any> {
    let body = JSON.stringify(option_package_model);
    return this.http.post(config.API_ENDPOINT + 'package/getbaseprice', body);
  }

  public addStandartPackage(packageModel: any): Observable<any> {
    let body = JSON.stringify(packageModel);
    return this.http.post(config.API_ENDPOINT + 'package/createstandard', body);
  }

  public addDefaultPackage(packageModel: any): Observable<any> {
    let body = JSON.stringify(packageModel);
    return this.http.post(
      config.API_ENDPOINT + 'optionsall/createdefault',
      body
    );
  }

  public getOptionsAll(option_search_model): Observable<any> {
    const body = JSON.stringify(option_search_model);
    return this.http.post(config.API_ENDPOINT + 'optionsall/listall', body);
  }

  public saveCoefficient(coefficientModel: any): Observable<any> {
    let body = JSON.stringify(coefficientModel);
    return this.http.post(config.API_ENDPOINT + 'optionsall/save', body);
  }

  public saveCoefficientCostOverView(coefficientModel: any): Observable<any> {
    let body = JSON.stringify(coefficientModel);
    return this.http.post(
      config.API_ENDPOINT + 'optionsall/updateoptioncostoverview',
      body
    );
  }

  public importPackageDiscounts(packageModel: any): Observable<any> {
    let body = JSON.stringify(packageModel);
    return this.http.post(config.API_ENDPOINT + 'discount/import', body);
  }

  public saveDiscount(packageModel: any): Observable<any> {
    let body = JSON.stringify(packageModel);
    return this.http.post(config.API_ENDPOINT + 'discount/save', body);
  }

  public deleteDiscount(discountmodel: any): Observable<any> {
    let body = JSON.stringify(discountmodel);
    return this.http.post(config.API_ENDPOINT + 'discount/delete', body);
  }

  public getPackageDiscountVersions(packageModel: any): Observable<any> {
    let body = JSON.stringify(packageModel);
    return this.http.post(config.API_ENDPOINT + 'discount/listall', body);
  }

  public savePackageDiscount(discount: any): Observable<any> {
    let body = JSON.stringify(discount);
    return this.http.post(config.API_ENDPOINT + 'package/savediscount', body);
  }

  private handleError(error: any) {
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
