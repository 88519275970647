import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../shared/smartadmin.config';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
@Injectable()
export class PricingCostService {
  private access_token: any;
  constructor(private http: HttpClient) {}

  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + config.API_URL + url).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  public savePricingCostRule(
    cost_rule_model: any,
    maxPriorityLength: number
  ): Observable<any> {
    let body = JSON.stringify(cost_rule_model);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(
      config.API_ENDPOINT +
        'costrule/save?maxPriorityLength=' +
        maxPriorityLength,
      body
    );
    // .map((response: Response) => {
    //   // login successful if there's a jwt token in the response
    //   return response.json();
    // })
    // .catch(this.handleError);
  }

  public getCostFields(): Observable<any> {
    // let searchModel ={};
    // let body = JSON.stringify(searchModel);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(
      config.API_ENDPOINT + 'pricingcost/getcostfields',
      null
    );
  }

  public getPricingFields(): Observable<any> {
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(
      config.API_ENDPOINT + 'pricingcost/getpricingfields',
      null
    );
  }

  public getAllPricingCosts(searchModel): Observable<any> {
    let body = JSON.stringify(searchModel);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(
      config.API_ENDPOINT + 'pricingcost/listallshort',
      body
    );
  }

  public createNewPricingCost(searchModel): Observable<any> {
    let body = JSON.stringify(searchModel);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(
      config.API_ENDPOINT + 'pricingcost/createnewpricingcost',
      body
    );
  }

  public createNewPricingCostTemplate(searchModel): Observable<any> {
    let body = JSON.stringify(searchModel);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(
      config.API_ENDPOINT + 'pricingcost/createnewpricingcosttemplate',
      body
    );
  }

  public reCalculateNewPricingCost(searchModel): Observable<any> {
    let body = JSON.stringify(searchModel);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(
      config.API_ENDPOINT + 'pricingcost/recalculatepricingcost',
      body
    );
  }

  public saveNewPricingCost(searchModel): Observable<any> {
    let body = JSON.stringify(searchModel);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(config.API_ENDPOINT + 'pricingcost/save', body);
  }

  public getCostRuleList(searchModel): Observable<any> {
    let body = JSON.stringify(searchModel);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(config.API_ENDPOINT + 'costrule/listall', body);
  }

  public getAllCostRuleVersions(searchModel): Observable<any> {
    let body = JSON.stringify(searchModel);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(config.API_ENDPOINT + 'costrule/listversions', body);
  }

  public deleteCostRule(cost_rule_model: any): Observable<any> {
    let body = JSON.stringify(cost_rule_model);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(config.API_ENDPOINT + 'costrule/delete', body);
  }

  // public getCostRuleItemById(_id: any): Observable<any> {
  //   // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
  //   // let options = new RequestOptions({ headers: headers });
  //   return this.http.get(config.API_ENDPOINT + 'discount/get?_id=' + _id);
  // }

  public deleteOption(option: any): Observable<any> {
    let body = JSON.stringify(option);
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token,'Content-Type': 'application/json' });
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.access_token, 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(config.API_ENDPOINT + 'option/delete', body);
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
