<header>
  <div class="row g-1 align-items-center">
    <div class="col">
      <h1 class="h2">Add Formula</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb small">
          <!-- <li class="breadcrumb-item"><a [routerLink]="['/admin/']">Admin</a></li> -->
          <li class="breadcrumb-item text-muted active" aria-current="page">Add Formula</li>
        </ol>
      </nav>

    </div>
  </div>
</header>
<div class="loading" *ngIf="isLoading">Loading</div>
<div class="section p-0">
  <div class="card-body p-4">
    <div *ngIf="!isLoading" class="col-sm-12 formula-wrapper">
      <ng-container *ngIf="!editMode">
        <div class="row">
          <div class="col-md-4">
            <div class="form-floating" style="margin-bottom: 20px;">
              <select class="form-select" id="profile" [(ngModel)]="selectedProfile"
                [disabled]="rules && rules.length>0" name="profileList">
                <option [value]="null">Select Profile</option>
                <option *ngFor="let profile of profileList" [ngValue]="profile">{{profile.Name}}</option>
              </select>
              <label for="profile">Profile</label>
            </div>
          </div>
          <div class="col-md-4">
            <make-codes (valueChangeMakeCode)="onChangeMakeCode($event)" selectclass='form-select'
              [makecode]="model.MakeCode" [disabled]="rules && rules.length>0" [style]="priceMakeStyle"></make-codes>
          </div>
          <div class="col-md-4">
            <div class="form-floating" style="margin-bottom: 20px;">
              <select class="form-select" id="seriecode" [(ngModel)]="model.SerieCode"
                [disabled]="rules && rules.length>0" name="serieCode" (ngModelChange)="getBodyTypesBySerie()">
                <option [value]="null">Select Model</option>
                <option *ngFor="let serie of serieList" value="{{serie.Code}}">{{serie.Name}}
                  ({{serie.Code}})</option>
              </select>
              <label for="seriecode">Model</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-floating" style="margin-bottom: 20px;">
              <select class="form-select" id="bodytypecode" [(ngModel)]="model.BodyTypeCode"
                [disabled]="rules && rules.length>0" name="bodyTypeCode"
                (ngModelChange)="getModelsBySerieAndBodyType()">
                <option [value]="null">Select Body Type</option>
                <option *ngFor="let body_type of bodyTypeList" value="{{body_type.Code}}">{{body_type.Name}}
                  ({{body_type.Code}})</option>
              </select>
              <label for="bodytypecode">Body Type</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating" style="margin-bottom: 20px;">
              <select class="form-select" id="modelcode" [(ngModel)]="model.ModelCode"
                [disabled]="rules && rules.length > 0" name="modelCode">
                <option [value]="null">Select Entity</option>
                <option *ngFor="let model of modelList" value="{{model.Code}}">{{model.Description}}
                  ({{model.Code}})</option>
              </select>
              <label for="modelcode">Entity</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating" style="margin-bottom: 20px;">
              <input type="text" id="modelversion" class="form-control"placeholder=Version [(ngModel)]="model.Version"
                [disabled]="rules && rules.length > 0" />
              <label for="modelversion">Version</label>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="form-group">
        <div [formGroup]="specificFormulaForm" *ngIf="selectedProfile && selectedProfile.Code == 'GrossSalesPrice'">
          <button style="margin-bottom: 20px;" class="btn btn-primary btn-sm" (click)="addSpecificFormulaRowInput()">Add
            Specific Formula</button>
          <div formArrayName="rows">
            <div *ngFor="let row of getSpecificFormulaRows().controls; let i=index" [formGroupName]="i">
              <div class="row">
                <div class="col-md-5">
                  <div class="form-floating" style="margin-bottom: 20px;">
                    <select class="form-select" id="name" formControlName="Name">
                      <option *ngFor="let pl of packageAndLineNames" [value]="pl.Name">{{pl.Name}}</option>
                    </select>
                    <label for="name">Name</label>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-floating" style="margin-bottom: 20px;">
                    <input type="text" class="form-control" id="formulavalue" placeholder="Formula Value"
                      formControlName="FormulaValue" />
                    <label for="formulavalue">Formula Value</label>
                  </div>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-md btn-warning" style="margin-bottom: 20px;" (click)="removeSpecificRow(i)"
                    [disabled]="rules && rules.length>0">Remove</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-floating" style="margin-bottom: 20px;"
          *ngIf="selectedProfile && selectedProfile.Code == 'OptionsExFactory'">
          <select class="form-select" id="specificSinglePackageFormula" [(ngModel)]="specificSinglePackageFormula">
            <option value="" [selected]="true">Choose a line (*optional)</option>
            <option *ngFor="let pl of lineNames" [value]="pl.Name">{{pl.Name}}</option>
          </select>
          <label for="specificSinglePackageFormula">Select Line For Add Specific Single Package Formula</label>
        </div>
        <div class="form-floating" style="margin-bottom: 20px;">
          <!-- <input type="text"
                   id="formula"
                   class="form-control"
                   [(ngModel)]="rawFormula"
                   [disabled]="rules && rules.length"
                   [mention]="profileList"
                   [mentionConfig]="{ triggerChar:'#', labelKey:'Name', mentionSelect: formatFormulaField }" /> -->
          <input type="text" id="formula" class="form-control" placeholder="Formula" [mention]="profileList"
          [mentionConfig]="{ triggerChar:'#', labelKey:'Name', mentionSelect: formatFormulaField }"  [(ngModel)]="rawFormula"
            [disabled]="rules && rules.length > 0" />
          <label for="formula">Formula</label>
        </div>
        <span class="help-block">
          <span class="supported-wrapper">
            <span>
              Type # to see supported profiles
            </span>
          </span>
          <span class="supported-wrapper">
            <span>
              Supported functions
            </span>
            <table class="supported-table">
              <tr>
                <td>IF</td>
                <td>AND</td>
              </tr>
            </table>
          </span>
          <span class="supported-wrapper">
            <span>
              Supported operators for rules
            </span>
            <table class="supported-table">
              <tr>
                <td>></td>
                <td>>=</td>
                <td>{{ '<' }}</td>
                <td>{{ '<=' }}</td>
                <td>=</td>
                <td>!=</td>
              </tr>
            </table>
          </span>
          <span class="supported-wrapper">
            <span>
              Supported operators for results
            </span>
            <table class="supported-table">
              <tr>
                <td>+</td>
                <td>-</td>
                <td>*</td>
                <td>/</td>
              </tr>
            </table>
          </span>
        </span>
      </div>
      <ng-container *ngIf="rules && rules.length>0">
        <accordion class="smart-accordion-default panel-group" [closeOthers]="false">
          <accordion-group [isOpen]="false" #group4>
            <h5 class="panel-title" accordion-heading>
              <i class="fa fa-fw" [ngClass]="{'fa-minus-circle txt-color-red': group4?.isOpen,
        'fa-plus-circle txt-color-green': !group4?.isOpen}"></i>
              Result Simulation
            </h5>
            <div style="width: 100%; overflow: hidden">
              <div *ngFor="let field of formulaFields" class="simulation-field">
                <div class="form-floating">
                <input class=" form-control" placeholder="{{ field.code }}" id="fieldvalue" type="text" [(ngModel)]="field.value">
                <label for="fieldvalue">{{ field.code }}</label>
              </div>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <button (click)="calculate()" class="btn btn-primary">Calculate</button>
            </div>
            <div *ngIf="simulationResult" style="margin-top: 20px;">
              <b>Result: </b> {{ simulationResult }}
            </div>
          </accordion-group>
        </accordion>
        <accordion *ngIf="rules && rules.length === 1" class="smart-accordion-default panel-group"
          [closeOthers]="false">
          <accordion-group [isOpen]="false" #group5>
            <h5 class="panel-title" accordion-heading>
              <i class="fa fa-fw" [ngClass]="{'fa-minus-circle txt-color-red': group5?.isOpen,
        'fa-plus-circle txt-color-green': !group5?.isOpen}"></i>
              Variable Simulation
            </h5>
            <div style="width: 100%; overflow: hidden">
              <div class="selected-variable-wrapper">
                <div class="simulation-field">
                  <div class="form-floating">
                  <input class="form-control" placeholder="Result" id="resultinput" type="text" [(ngModel)]="resultInput">
                  <label for="resultinput">Result</label>
                </div>
                </div>
                <div class="simulation-field">
                  <div class="form-floating">
                  <select class="form-select" id="selectedVariable" name="selectedVariable"
                    [(ngModel)]="selectedVariableCode">
                    <option [value]="null">Select Variable</option>
                    <option *ngFor="let field of variableFormulaFields" [ngValue]="field.code">{{field.code}}</option>
                  </select>
                  <label for="selectedVariable">Variable</label>
                </div>
                </div>
              </div>
              <div *ngFor="let field of variableFormulaFields" class="simulation-field">
                <div class="form-floating">
                <input class="form-control" type="text" placeholder="{{ field.code }}" id="fieldvalues" [(ngModel)]="field.value"
                  [disabled]="selectedVariableCode === field.code">
                  <label for="fiealdvalues">{{ field.code }}</label>
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <button (click)="calculateVariable()" class="btn btn-primary">Calculate</button>
            </div>
            <div *ngIf="variableSimulationResult" style="margin-top: 20px;">
              <b>{{ selectedVariableCode }}: </b> {{ variableSimulationResult }}
            </div>
          </accordion-group>
        </accordion>
        <table class="table formula-table">
          <thead>
            <tr>
              <th>Priority</th>
              <th>Rules</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rule of rules">
              <td>{{ rule.Priority }}</td>
              <td>
                <ul>
                  <li *ngFor="let item of rule.Rules">{{ item === '1>0' ? 'No rules applied' : item }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li *ngFor="let item of rule.RuleResult">{{ item }}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <button (click)="confirm()" class="btn btn-primary">Confirm</button>
        <button (click)="cancel()" class="btn btn-default">Cancel</button>
      </ng-container>
      <button *ngIf="!(rules && rules.length>0)" (click)="generateFormula()" [disabled]="!isFormValid()"
        class="btn btn-primary">Save</button>
    </div>
  </div>
</div>
