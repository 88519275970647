import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../shared/smartadmin.config';
import { AuthService } from './auth.service';
import { catchError, delay, map, Observable, throwError } from 'rxjs';

@Injectable()
export class SerieService {
  private access_token: any;
  constructor(private http: HttpClient, private auth_service: AuthService) {
    let user_info = JSON.parse(this.auth_service.getAccessToken());
    console.log(user_info);
    this.access_token = user_info.access_token;
  }

  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + config.API_URL + url).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  public addNewSerie(serieModel: any): Observable<any> {
    let body = JSON.stringify(serieModel);
    return this.http.post(config.API_ENDPOINT + 'serie/save', body);
  }

  public getSerieById(serie_id: any): Observable<any> {
    return this.http.get(config.API_ENDPOINT + 'serie/get?id=' + serie_id);
  }

  public createNewProductFile(productfileModel: any): Observable<any> {
    let body = JSON.stringify(productfileModel);
    return this.http.post(config.API_ENDPOINT + 'productfile/save', body);
  }

  public getAllSeries(search_model: any): Observable<any> {
    let body = JSON.stringify(search_model);
    return this.http.post(config.API_ENDPOINT + 'serie/listall', body);
  }

  public getAllSeriesCustom(): Observable<any> {
    return this.http.get(config.API_ENDPOINT + 'serie/listallwithproductfile');
  }

  public getAllSeriesWithOutProductFile(search_model: any): Observable<any> {
    let body = JSON.stringify(search_model);
    return this.http.post(
      config.API_ENDPOINT + 'serie/listallwithoutproductfile',
      body
    );
  }

  public deleteSerie(serie: any): Observable<any> {
    let body = JSON.stringify(serie);
    return this.http.post(config.API_ENDPOINT + 'serie/delete', body);
  }

  private handleError(error: any) {
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
