import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { config } from '../../shared/smartadmin.config';
import { Observable, throwError } from 'rxjs';
import { catchError, map, delay } from 'rxjs/operators';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + config.API_URL + url).pipe(
      delay(100),
      map((data: any) => {
        data.data || data;
      }),
      catchError(this.handleError)
    );
  }

  private getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  public checkUser(user_model: any) {
    let body = JSON.stringify(user_model);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });
    return this.http
    //.post('https://smartpricing-ws-dev.azurewebsites.net/api/jwt', body, {
     .get(config.API_ENDPOINT + 'jwt/GetToken?username='+user_model.username +'&password='+user_model.password, {
        headers: headers,
      })
      .pipe(
        map((response: any) => {
          console.log(response);
          if (response) this.setAccessToken(response);
          // login successful if there's a jwt token in the response
          return response;
        }),
        catchError(this.handleError)
      );
  }

  public getAccessToken() {
    let auth_info = JSON.parse(
      JSON.stringify(localStorage.getItem('auth_info'))
    );
    return auth_info;
  }

  public getUserMakeCode() {
    let auth_info = JSON.parse(
      JSON.stringify(localStorage.getItem('auth_info'))
    );
    if (auth_info.user_make_codes) {
      let makecodes = auth_info.user_make_codes.split(',');
      return makecodes[0];
    } else return '';
  }

  private setAccessToken(token_info: any) {
    console.log('login_token_info:', token_info);
    let auth_model = {
      access_token: token_info.access_token,
      expires_in: token_info.expires_in,
      id: token_info.user_id,
      user_full_name: token_info.user_full_name,
      user_email: token_info.user_email,
      user_make_codes: token_info.user_make_codes,
    };
    localStorage.setItem('auth_info', JSON.stringify(auth_model));
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
