// server-errors.interceptor.ts
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, timeout, finalize } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { NotificationService } from '../shared/utils/notification.service';
import { Router } from '../../../node_modules/@angular/router';
import { ErrorLogService } from './services/error-log.service';
import { TimeoutError } from 'rxjs';
import { AlertifyService } from '../shared/utils/alertify.service';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(private injector: Injector,private alertfiy:AlertifyService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('jwt') !== -1) {
      return next.handle(request);
    }
    const auth_service = this.injector.get(AuthService);
    const error_log_service = this.injector.get(ErrorLogService);
    const notificationService = this.injector.get(NotificationService);
    const router = this.injector.get(Router);
    let user_info = JSON.parse(auth_service.getAccessToken());
    let access_token = user_info.access_token;
    // add a custom header
    const customReq = request.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         Authorization: 'Bearer ' + access_token,
      }),
    });
    // If the call fails, retry until 5 times before throwing an error

    return next.handle(customReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }),
      catchError((response) => {
        if (response instanceof HttpErrorResponse) {
          console.log('service_error_handler:', response);
          if (!navigator.onLine) {
            // Handle offline error
            this.alertfiy.error("No internet connection");
          } else {
            if (response.status === 401) {
              if (user_info.user_email && user_info.id)
                // router.navigate(['/auth/locked',{return_url: err.url}]);
                router.navigate(['/auth/locked']);
              else router.navigate(['/auth/login']);
            } else if (response.status === 0) router.navigate(['/error']);
          }
          //error log service
          // let error_info_model ={
          //   'errorType':'ServiceError',
          //   'ErrorMessage':err.message,
          //   'username':user_info.user_email,
          //   'ControllerPath':err.url
          // }
          // error_log_service.insertError(error_info_model).subscribe(res=>{

          // });
        }

        return throwError(response);
      }),
      timeout(300000),
    );
  }
}
