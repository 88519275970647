import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { I18nModule } from './i18n/i18n.module';

import { SmartadminWidgetsModule } from './widgets/smartadmin-widgets.module';
import { SmartpricingComponentsModule } from './components/smartpricing-components.module';

import { UtilsModule } from './utils/utils.module';
import { LayoutsModule } from '../layouts/layouts.module';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    LayoutsModule,
    I18nModule,
    UtilsModule,
    SmartadminWidgetsModule,
    SmartpricingComponentsModule,
  ],
})
export class SharedModule {}
