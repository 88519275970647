import { Component, HostListener } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],

})
export class SwiperComponent {



  imageSources = [

    "../../../assets/configasehome.png",
    "../../../assets/images/avatar/p.png"
  ];

  currentImageIndex = 0;
  heroSectionStyle: any;
  imageIndex = 0;
  interval: any;
  constructor() {
   }

  ngOnInit(): void {
    this.startCarousel();

  }


  startCarousel() {
    this.interval = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  nextSlide() {
    this.imageIndex = (this.imageIndex + 1) % this.imageSources.length;
  }
}



  /*
  backgroundImages: string[] = [
    "url('../../../assets/images/avatar/p.png')",
    "url('../../../assets/images/avatar/pp.png')",

  ];

  currentImageIndex = 0;
  heroSectionStyle: any;

  constructor() { }

  ngOnInit(): void {
    this.changeBackgroundImage();
    setInterval(() => {
      this.changeBackgroundImage();
    }, 5000);
  }

  changeBackgroundImage() {
    this.heroSectionStyle = {
      '--background-url': this.backgroundImages[this.currentImageIndex]
    };

    this.currentImageIndex++;
    if (this.currentImageIndex === this.backgroundImages.length) {
      this.currentImageIndex = 0;
    }
  }
}*/



