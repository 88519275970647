import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../shared/smartadmin.config';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
@Injectable()
export class ProductFileService {
  private access_token: any;
  constructor(private http: HttpClient) {}

  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + config.API_URL + url).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  public createNewProductFile(productfile: any): Observable<any> {
    let body = JSON.stringify(productfile);
    console.log('service_productfilemodel:', body);
    return this.http.post(config.API_ENDPOINT + 'productfile/save', body);
  }

  public addProductFileModel(
    productfileModel: any,
    productFileModeList: any[]
  ): Observable<any> {
    let body = JSON.stringify(productfileModel);
    return this.http.post(config.API_ENDPOINT + 'productfile/savemodel', body);
  }

  public getAllProductFiles(search_model: any): Observable<any> {
    let body = JSON.stringify(search_model);
    return this.http.post(config.API_ENDPOINT + 'productfile/listall', body);
  }

  public getAllProductFileVersions(search_model: any): Observable<any> {
    let body = JSON.stringify(search_model);
    return this.http.post(
      config.API_ENDPOINT + 'productfile/listversions',
      body
    );
  }

  public getProductFile(id: any): Observable<any> {
    return this.http.get(config.API_ENDPOINT + 'productfile/get?id=' + id);
  }

  public getAllProductFileModels(productFileId: any): Observable<any> {
    let product_file_model = {
      _Id: productFileId,
    };
    let body = JSON.stringify(product_file_model);
    return this.http.post(
      config.API_ENDPOINT + 'productfile/getmodelnames',
      body
    );
  }

  public getModelRules(model_rules_filter: any): Observable<any> {
    let body = JSON.stringify(model_rules_filter);
    return this.http.post(
      config.API_ENDPOINT + 'productfile/getmodeloptions',
      body
    );
  }

  public getModelOptionsByCode(model_option_filter: any): Observable<any> {
    let body = JSON.stringify(model_option_filter);
    return this.http.post(
      config.API_ENDPOINT + 'productfile/getmodeloptionsbycode',
      body
    );
  }

  public saveNewRule(rule_model: any, modelCode: string): Observable<any> {
    let body = JSON.stringify(rule_model);
    return this.http.post(
      config.API_ENDPOINT +
        'productfile/savefilemodeloptions?modelCode=' +
        modelCode,
      body
    );
  }

  public deleteProductFile(product_file: any): Observable<any> {
    let body = JSON.stringify(product_file);
    return this.http.post(config.API_ENDPOINT + 'productfile/delete', body);
  }

  public confirmProductFile(product_file_info: any): Observable<any> {
    let body = JSON.stringify(product_file_info);
    return this.http.post(config.API_ENDPOINT + 'productfile/activate', body);
  }

  private handleError(error: any) {
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }

  public getProductFileCount(): Observable<any> {
    return this.http.post(config.API_ENDPOINT + 'productfile/productfilecount', {});
  }
}
