import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: [],
})
export class RegisterComponent implements OnInit {
  public termsAgreed = false;

  constructor(private router: Router) {}

  ngOnInit() {}

  register(event) {
    event.preventDefault();
    this.router.navigate(['/dashboard']);
  }

  openModal(event, template: TemplateRef<any>) {
    event.preventDefault();
  }

  onTermsAgree() {
    this.termsAgreed = true;
  }

  onTermsClose() {}
}
