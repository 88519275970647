import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { LoaderService } from '../../../core/services/loader.service';
import { AlertComponent } from '@coreui/angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: [AuthService],
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private login_model: any = {};
  login_form_group: FormGroup;
  error;;
  isLoading: boolean = false;
  constructor(private router: Router, private auth_service: AuthService, private loader_service: LoaderService) {
    this.login_form_group = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
  }

  login(event) {
    this.loader_service.setLoading(true)
    this.isLoading = true;
    this.login_model.username = this.login_form_group.value.email;
    this.login_model.password = this.login_form_group.value.password;
    this.auth_service.checkUser(this.login_model).subscribe({
      next: (result) => {
        console.log('3', result);
        if (result != null) {
          window.localStorage.setItem('username', result.user_full_name);
          this.router.navigate(['/admin']);
        } else this.error = 'Hatalı kullaıcı bilgileri';
        this.isLoading = false;
      },
      error: (e) => {
        this.loader_service.setLoading(false)
        this.error = "Invalid Credentials"
        // TODO: change this error structure to alertify after fix it, umcomment below
        // this.login_form_group.controls['email'].setValue('');
        // this.login_form_group.controls['password'].setValue('');
      },
      complete: () => {
        console.info('complete')
        this.loader_service.setLoading(false)
      }
    });
  }


  isMobile: boolean = false;

  checkScreenWidth(): void {
    this.isMobile = window.innerWidth < 768;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
}
