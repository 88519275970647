import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';
import { ProductFileService } from '../../core/services/product-file.service';
import { ModelService } from '../../core/services/model.service';
import { PackageService } from '../../core/services/package.service';
import { LineService } from '../../core/services/line.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [PackageService],
})
export class DashboardComponent implements OnInit {
  public productFileStatistic = {
    producFileCount: {
      bmw: 0,
      bmwMini: 0,
      bmwMot: 0,
      bmwI: 0,
    },
    totalModelCount: {
      bmw: 0,
      bmwMini: 0,
      bmwMot: 0,
      bmwI: 0,
    },
    packageCount:{
      _base: 0,
      _singlePackage: 0,
      _7R3: 0,
      _912: 0,
      _976: 0,
      _9CX: 0,
    },
    totalLineCount: {
      bmw: 0,
      bmwMini: 0,
      bmwMot: 0,
      bmwI: 0,
    },
  };
  //Product File Chart
  public productFileChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };
  public productFileChartLabels = [
    ['CC91'],
    ['CC28'],
    ['CC27'],
    ['CC8'],
  ];
  public productFileChartDatasets;
  public productFileChartLegend = true;


  //Model Chart
  public modelChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };
  public modelChartLabels = [
    ['CC91'],
    ['CC28'],
    ['CC27'],
    ['CC8'],
  ];
  public modelChartDatasets;
  public modelChartLegend = true;


  //Package Chart
  public packageChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };
  public packageChartLabels = [
    ['Base'],
    ['Single Package'],
    ['7R3'],
    ['912'],
    ['979'],
    ['9CX'],
  ];
  public packageChartDatasets;
  public packageChartLegend = true;


  //Line Chart
  public lineChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };
  public lineChartLabels = [
    ['CC91'],
    ['CC28'],
    ['CC27'],
    ['CC8'],
  ];
  public lineChartDatasets;
  public lineChartLegend = true;

  // public lineChartData: ChartConfiguration<'line'>['data'] = {
  //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  //   datasets: [
  //     {
  //       data: [65, 59, 80, 81, 56, 55, 40],
  //       label: 'Series A',
  //       fill: true,
  //       tension: 0.5,
  //       borderColor: 'black',
  //       backgroundColor: 'rgba(255,0,0,0.3)',
  //     },
  //   ],
  // };
  // public lineChartOptions: ChartOptions<'line'> = {
  //   responsive: false,
  // };
  // public lineChartLegend = true;
  // public scatterChartDatasets: ChartConfiguration<'scatter'>['data']['datasets'] =
  //   [
  //     {
  //       data: [
  //         { x: 1, y: 1 },
  //         { x: 2, y: 3 },
  //         { x: 3, y: -2 },
  //         { x: 4, y: 4 },
  //         { x: 5, y: -3 },
  //       ],
  //       label: 'Series A',
  //       pointRadius: 10,
  //     },
  //   ];

  // public scatterChartOptions: ChartConfiguration<'scatter'>['options'] = {
  //   responsive: false,
  // };
  // public doughnutChartLabels: string[] = [
  //   'Download Sales',
  //   'In-Store Sales',
  //   'Mail-Order Sales',
  // ];
  // public doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] =
  //   [
  //     { data: [350, 450, 100], label: 'Series A' },
  //     { data: [50, 150, 120], label: 'Series B' },
  //     { data: [250, 130, 70], label: 'Series C' },
  //   ];

  // public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
  //   responsive: false,
  // };
  constructor(
    private productFileService: ProductFileService,
    private modelService: ModelService,
    private packageService: PackageService,
    private lineService: LineService
  ) {}

  ngOnInit(): void {
    this.getDashboardStatistic();
  }

  getDashboardStatistic() {
    this.productFileService.getAllProductFiles({}).subscribe((result) => {
      result.map((x: any) => {
        switch (x.MakeCode) {
          case 'BMW':
            this.productFileStatistic.producFileCount.bmw++;
            break;
          case 'BMW-MINI':
            this.productFileStatistic.producFileCount.bmwMini++;
            break;
          case 'BMW-MOT':
            this.productFileStatistic.producFileCount.bmwMot++;
            break;
          case 'BMWI':
            this.productFileStatistic.producFileCount.bmwI++;
            break;
          default:
            break;
        }
      });
      this.productFileChartDatasets = [
        {
          data: [
            this.productFileStatistic.producFileCount.bmw,
            this.productFileStatistic.producFileCount.bmwMini,
            this.productFileStatistic.producFileCount.bmwMot,
            this.productFileStatistic.producFileCount.bmwI,
          ],
        },
      ];
    });

    this.modelService
      .getAllModels({ excludeproductfilecheck: true })
      .subscribe((result) => {
        result.map((x: any) => {
          switch (x.MakeCode) {
            case 'BMW':
              this.productFileStatistic.totalModelCount.bmw++;
              break;
            case 'BMW-MINI':
              this.productFileStatistic.totalModelCount.bmwMini++;
              break;
            case 'BMW-MOT':
              this.productFileStatistic.totalModelCount.bmwMot++;
              break;
            case 'BMWI':
              this.productFileStatistic.totalModelCount.bmwI++;
              break;
            default:
              break;
          }
        });
        this.modelChartDatasets = [
          {
            data: [
              this.productFileStatistic.totalModelCount.bmw,
              this.productFileStatistic.totalModelCount.bmwMini,
              this.productFileStatistic.totalModelCount.bmwMot,
              this.productFileStatistic.totalModelCount.bmwI,
            ],
          },
        ];
      });
    
      
    

      this.packageService.getAllPackageStatistics().subscribe((result) => {
        result.map((x: any) => {
          switch (x.Name) {
            case 'BASE':
              this.productFileStatistic.packageCount._base++;
              break;
            case 'Single Package':
              this.productFileStatistic.packageCount._singlePackage++;
              break;
            case '7R3':
              this.productFileStatistic.packageCount._7R3++;
              break;
            case '912':
              this.productFileStatistic.packageCount._912++;
              break;
            case '976':
              this.productFileStatistic.packageCount._976++;
              break;
            case '9CX':
              this.productFileStatistic.packageCount._9CX++;
              break;
            default:
              break;
          }
        });
        this.packageChartDatasets = [
          {
            data: [
              this.productFileStatistic.packageCount._base,
              this.productFileStatistic.packageCount._singlePackage,
              this.productFileStatistic.packageCount._7R3,
              this.productFileStatistic.packageCount._912,
              this.productFileStatistic.packageCount._976,
              this.productFileStatistic.packageCount._9CX,
            ],
          },
        ];
      });

      this.lineService.getAllLines({}).subscribe((result) => { 
        result.map((x: any) => {
          switch (x.MakeCode) {
            case 'BMW':
              this.productFileStatistic.totalLineCount.bmw++;
              break;
            case 'BMW-MINI':
              this.productFileStatistic.totalLineCount.bmwMini++;
              break;
            case 'BMW-MOT':
              this.productFileStatistic.totalLineCount.bmwMot++;
              break;
            case 'BMWI':
              this.productFileStatistic.totalLineCount.bmwI++;
              break;
            default:
              break;
          }
        });
        this.lineChartDatasets = [
          {
            data: [
              this.productFileStatistic.totalLineCount.bmw,
              this.productFileStatistic.totalLineCount.bmwMini,
              this.productFileStatistic.totalLineCount.bmwMot,
              this.productFileStatistic.totalLineCount.bmwI,
            ],
          },
        ];
      });

  }

  getUsername() {
    return localStorage.getItem('username');
  }

}
