import { Component, HostListener, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import emailjs from "@emailjs/browser";


@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
 @ViewChild('myVideo') myVideo: any;

 contactForm: FormGroup;
 form: FormGroup;


  constructor(private el: ElementRef, private http: HttpClient, private fb: FormBuilder) { }



  oynatVideo() {
    const resim = document.getElementById('resim');
    const video = document.getElementById('video');
    if (resim && video) {
      resim.style.display = 'none';
      video.style.display = 'block';
      (video as HTMLVideoElement).play();
    }
  }

  ngAfterViewInit() {
    this.myVideo.nativeElement.muted = true;
    this.myVideo.nativeElement.play();
  }




  ngOnInit(): void {
    this.form = this.fb.group({
      from_name: ['', Validators.required],
      from_surname: ['', Validators.required],
      from_email: ['', [Validators.required, Validators.email]],
      from_number: ['', Validators.required],
      from_job: ['', Validators.required],
      from_com: ['', Validators.required],
      from_detail: ['', Validators.required]
    });
    this.checkScreenWidth();
  }






  async send() {
    if (this.form.valid){
    emailjs.init('9ahmA54Hubjg-Wq0W');
    try {
      const response = await emailjs.send('service_m1yfr25', 'template_2qe7m0f', {
        from_name: this.form.value.from_name,
        from_surname: this.form.value.from_surname,
        from_email: this.form.value.from_email,
        from_number: this.form.value.from_number,
        from_job: this.form.value.from_job,
        from_com: this.form.value.from_com,
        from_detail: this.form.value.from_detail
      });

      console.log('Email sent:', response);
      alert('Message has been sent');
      this.form.reset();
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email. Please try again later.');
    }
  }
  else{
    alert('Please fill all the fields.')
  }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.handleNavbarScroll();
  }

  private handleNavbarScroll() {
    const navbar = this.el.nativeElement.querySelector('#navbar');
    const scrolled = window.scrollY > 10;

    if (scrolled) {
      navbar.classList.add('navbar-scrolled');
    } else {
      navbar.classList.remove('navbar-scrolled');
    }
  }




  isMobile: boolean = false;

  checkScreenWidth(): void {
    this.isMobile = window.innerWidth < 768;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }



  }





