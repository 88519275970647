import { Component, Input, OnInit } from '@angular/core';
import { BodyTypeService } from '../../../core/services/body-type.service';
import { ModelService } from '../../../core/services/model.service';
import { PricingCostService } from '../../../core/services/pricing-cost.service';
import { SerieService } from '../../../core/services/serie.service';
import { NotificationService } from '../../../shared/utils/notification.service';
import * as math from 'mathjs';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AlertifyService } from '../../utils/alertify.service';

@Component({
  selector: 'app-add-formula',
  templateUrl: './add-formula.component.html',
  styleUrls: ['./add-formula.component.scss'],
})
export class AddFormulaComponent implements OnInit {
  isLoading = false;
  // tslint:disable-next-line: max-line-length
  // IF(AND(AdditionalCost>50, AdditionalCost<60, AGInvoiceSupport=5), CIF+22+(CC/100), IF(AdditionalCost<30, -(CIF+100)+28+(CostInStock/100+ExFactory), IF(DC1=30, CIF+28+(CostInStock/100), -CashBack+100)))
  @Input() rawFormula = '';
  @Input() selectedProfile = null;
  @Input() editMode = false;
  @Input() model = {
    MakeCode: null,
    ModelCode: null,
    SerieCode: null,
    BodyTypeCode: null,
    Version: null,
  };
  @Input() packageNames = [];
  @Input() lineNames = [];
  packageAndLineNames = [];
  @Input() specificFormulaValues = [];
  specificFormulaForm: FormGroup;
  additionalSelectionsForMention = [];
  selectedPackage;
  profileList = [];
  serieList = [];
  bodyTypeList = [];
  modelList = [];
  rules = [];
  ruleIndex = 0;
  payload = [];
  priceMakeStyle = { height: '36px' };
  formulaFields = [];
  variableFormulaFields = [];
  simulationResult = '';
  selectedVariableCode = '';
  variableSimulationResult = '';
  resultInput = '';
  formula = '';
  specificSinglePackageFormula = '';
  endFormula = '';
  mentionItems: any;

  constructor(
    private pricingCostService: PricingCostService,
    private notificationService: NotificationService,
    private bodyService: BodyTypeService,
    private serieService: SerieService,
    private modelService: ModelService,
    private formBuilder: FormBuilder,
    private alertify:AlertifyService
  ) {}

  ngOnInit() {
    this.getProfiles();
    this.createSpecificFormulaForm();
    this.runMentionItems();
  }

  createSpecificFormulaForm() {
    this.packageAndLineNames = this.packageNames.concat(this.lineNames);

    this.specificFormulaForm = this.formBuilder.group({
      rows: this.formBuilder.array([]),
    });
    this.fillSpecificFormulaData();
  }

  newSpecificFormulaRow(name?, formulaValue?) {
    return this.formBuilder.group({
      Name: [name ? name : ''],
      FormulaValue: [formulaValue ? formulaValue : ''],
    });
  }

  getSpecificFormulaRows() {
    return this.specificFormulaForm.get('rows') as FormArray;
  }

  addSpecificFormulaRowInput() {
    this.getSpecificFormulaRows().push(this.newSpecificFormulaRow());
  }

  removeSpecificRow(i) {
    this.getSpecificFormulaRows().removeAt(i);
  }

  fillSpecificFormulaData() {
    this.specificFormulaValues.forEach((formula) => {
      if (this.packageAndLineNames.some((x) => x.Name == formula.Name))
        this.getSpecificFormulaRows().push(
          this.newSpecificFormulaRow(formula.Name, formula.FormulaValue)
        );
    });
    const singleFormula = this.specificFormulaValues.find(
      (x) => x.Name == 'Single Package'
    );
    if (singleFormula)
      this.specificSinglePackageFormula =
        singleFormula.FormulaValue.split('~')[0];
  }

  formatFormulaField(profile) {
    return profile.Code;
  }

  formatFormulaPackageField = (profile): any => {
    this.selectedPackage = profile.label;
    //this.mentionItems[0].items = [{ label : "PackagePrice" }];
    return profile.Name + '~';
  };

  formatFormulaLineField = (profile): any => {
    return profile.Name + '~';
  };

  formatFormulaPackageSelectionsField(profile): string {
    return profile.label;
  }

  generateFormula(): void {
    this.reset();
    try {
      this.addTagToPackageNameFromFormula();
      this.fillSpecificFormulaToArray();
      this.parseFormula(this.rawFormula.replace(/\s+/g, ''));
    } catch (error) {
      this.reset();
      this.alertify.error('Please check the formula and try again.');
    }
  }

  runMentionItems() {
    this.mentionItems = [
      {
        items: [],
        triggerChar: '.',
        mentionSelect: this.formatFormulaPackageSelectionsField,
      },
    ];
  }

  reset(): void {
    this.rules = [];
    this.payload = [];
    this.formulaFields = [];
    this.variableFormulaFields = [];
    this.selectedVariableCode = '';
    this.simulationResult = '';
    this.variableSimulationResult = '';
    this.resultInput = '';
    this.ruleIndex = 0;
    this.setDisableSpecificInputs(false);
  }

  parseFormula(rawFormula: string): void {
    if (rawFormula && rawFormula.indexOf('IF(AND(') === 0) {
      const formulaLength = rawFormula.length;
      rawFormula = rawFormula.substr(7, formulaLength - 8);
      const rest = rawFormula.substring(rawFormula.indexOf(')') + 2);
      const conditions = rawFormula.split(')')[0].split(',');
      this.payload.push({
        FieldName: this.selectedProfile.Name,
        FieldCode: this.selectedProfile.Code,
        Rules: this.generateNodes(conditions, false),
        RuleResult: [],
        Priority: this.ruleIndex + 1,
        MakeCode: this.model.MakeCode,
        ModelCode: this.model.ModelCode,
        SerieCode: this.model.SerieCode,
        BodyType: this.model.BodyTypeCode,
        VersionDate: this.model.Version,
        FormulaValue: this.endFormula,
        SpecificFormulaValues: this.specificFormulaValues.length
          ? this.specificFormulaValues
          : null,
      });
      this.rules.push({
        Rules: conditions,
        RuleResult: [],
        Priority: this.ruleIndex + 1,
      });
      this.ruleIndex++;
      const trueResult = rest.split(',')[0];
      this.parseFormula(trueResult);
      const falseResult = rest.substring(rest.indexOf(',') + 1);
      this.parseFormula(falseResult);
    } else if (rawFormula && rawFormula.indexOf('IF(') === 0) {
      const formulaLength = rawFormula.length;
      rawFormula = rawFormula.substr(3, formulaLength - 4);
      const formulaSplit = rawFormula.split(',');
      const condition = formulaSplit[0];
      this.payload.push({
        FieldName: this.selectedProfile.Name,
        FieldCode: this.selectedProfile.Code,
        Rules: this.generateNodes([condition], false),
        RuleResult: [],
        Priority: this.ruleIndex + 1,
        MakeCode: this.model.MakeCode,
        ModelCode: this.model.ModelCode,
        SerieCode: this.model.SerieCode,
        BodyType: this.model.BodyTypeCode,
        VersionDate: this.model.Version,
        FormulaValue: this.endFormula,
        SpecificFormulaValues: this.specificFormulaValues.length
          ? this.specificFormulaValues
          : null,
      });
      console.log('payload', this.payload);
      this.rules.push({
        Rules: [condition],
        RuleResult: [],
        Priority: this.ruleIndex + 1,
      });
      this.ruleIndex++;
      const trueResult = formulaSplit[1];
      this.parseFormula(trueResult);
      const falseResult = rawFormula.substr(
        condition.length + trueResult.length + 2,
        rawFormula.length - 1
      );
      this.parseFormula(falseResult);
    } else {
      if (
        this.payload[this.ruleIndex - 1] &&
        !this.payload[this.ruleIndex - 1].RuleResult.length
      ) {
        this.payload[this.ruleIndex - 1].RuleResult.push(
          this.generateNodes([rawFormula], true)[0]
        );
        this.rules[this.ruleIndex - 1].RuleResult.push(rawFormula);
      } else {
        this.payload.push({
          FieldName: this.selectedProfile.Name,
          FieldCode: this.selectedProfile.Code,
          Rules: [
            {
              Field: {
                Name: this.selectedProfile.Name,
                Code: this.selectedProfile.Code,
                Description: this.selectedProfile.Description,
              },
              Operator: '=',
              CompareType: 1,
              ValueCompare: null,
              FieldCompare: {
                Name: this.selectedProfile.Name,
                Code: this.selectedProfile.Code,
                Description: this.selectedProfile.Description,
              },
              Operand: '',
            },
          ],
          RuleResult: this.generateNodes([rawFormula], true),
          Priority: this.ruleIndex + 1,
          MakeCode: this.model.MakeCode,
          ModelCode: this.model.ModelCode,
          SerieCode: this.model.SerieCode,
          BodyType: this.model.BodyTypeCode,
          VersionDate: this.model.Version,
          FormulaValue: this.endFormula,
          SpecificFormulaValues: this.specificFormulaValues.length
            ? this.specificFormulaValues
            : null,
        });
        this.rules.push({
          Rules: ['1>0'],
          RuleResult: [rawFormula],
          Priority: this.ruleIndex + 1,
        });
      }
    }
  }

  generateNodes(conditions: string[], isResult: boolean): any[] {
    const sets = [];
    conditions.forEach((condition) => {
      let isGroup = false;
      let groupIndex = null;
      let currentOperatorIndex = null;
      let currentOperator = null;
      if (isResult) {
        if (!(condition.charAt(0) === '+' || condition.charAt(0) === '-')) {
          condition = '+' + condition;
        }
        for (let i = 0; i < condition.length; i++) {
          const char = condition.charAt(i);
          if (!isGroup) {
            if (char === '(') {
              isGroup = true;
              groupIndex = i;
              currentOperatorIndex = null;
              currentOperator = null;
            } else if (['+', '-', '*', '/'].indexOf(char) > -1) {
              if (currentOperatorIndex !== null) {
                let tempValueForAdditionalChars = '';
                let value = condition.substr(
                  currentOperatorIndex + 1,
                  i - currentOperatorIndex - 1
                );
                if (value.includes('~')) {
                  const splittedValue = value.split('~');
                  tempValueForAdditionalChars = splittedValue[0] + '~';
                  value = splittedValue[1];
                }
                const field = this.profileList.find(
                  (profile) => profile.Code === value
                );
                if (!field && isNaN(+value)) {
                  throw new Error('value');
                }
                this.addFormulaField(field, tempValueForAdditionalChars);
                const operator = currentOperator;
                const model = {
                  Operator: operator,
                  CompareType: field ? 1 : 0,
                  ValueCompare: field ? null : value,
                  FieldCompare: field
                    ? {
                        Name: field.Name,
                        Code: field.Code,
                        Description: field.Description,
                      }
                    : null,
                  GroupCompare: null,
                };
                sets.push(model);
              }
              currentOperatorIndex = i;
              currentOperator = char;
            } else if (
              currentOperatorIndex !== null &&
              i === condition.length - 1
            ) {
              let tempValueForAdditionalChars = '';
              let value = condition.substr(
                currentOperatorIndex + 1,
                i - currentOperatorIndex
              );
              if (value.includes('~')) {
                const splittedValue = value.split('~');
                tempValueForAdditionalChars = splittedValue[0] + '~';
                value = splittedValue[1];
              }
              let field = this.profileList.find(
                (profile) => profile.Code === value
              );
              this.addFormulaField(field, tempValueForAdditionalChars);
              if (!field && isNaN(+value)) {
                throw new Error('value');
              }
              const operator = currentOperator;
              const model = {
                Operator: operator,
                CompareType: field ? 1 : 0,
                ValueCompare: field ? null : value,
                FieldCompare: field
                  ? {
                      Name: field.Name,
                      Code: field.Code,
                      Description: field.Description,
                    }
                  : null,
                GroupCompare: null,
              };
              sets.push(model);
            }
          } else {
            if (char === ')') {
              const group = condition.substr(groupIndex, i - groupIndex + 1);
              const operator = condition.substr(groupIndex - 1, 1);
              const model = {
                operator: operator,
                CompareType: 2,
                ValueCompare: null,
                FieldCompare: null,
                GroupCompare: this.generateGroupCompare(group),
              };
              sets.push(model);
              isGroup = false;
              groupIndex = null;
            }
          }
        }
      } else {
        let splitOperator = null;
        if (condition.indexOf('>=') > -1) {
          splitOperator = '>=';
        } else if (condition.indexOf('<=') > -1) {
          splitOperator = '<=';
        } else if (condition.indexOf('>') > -1) {
          splitOperator = '>';
        } else if (condition.indexOf('<') > -1) {
          splitOperator = '<';
        } else if (condition.indexOf('=') > -1) {
          splitOperator = '==';
        } else if (condition.indexOf('!=') > -1) {
          splitOperator = '!=';
        }
        if (splitOperator !== null) {
          let tempValueForAdditionalChars = '';
          const split = condition.split(
            splitOperator == '==' ? '=' : splitOperator
          );
          if (split[0].includes('~')) {
            const splittedValue = split[0].split('~');
            tempValueForAdditionalChars = splittedValue[0] + '~';
            split[0] = splittedValue[1];
          }
          const field = this.profileList.find(
            (profile) => profile.Code === split[0]
          );
          const compare = split[1];
          if (!field && Number.isNaN(+compare)) {
            throw new Error('value');
          }
          this.addFormulaField(field, tempValueForAdditionalChars);
          tempValueForAdditionalChars = '';
          if (split[1].includes('~')) {
            const splittedValue = split[1].split('~');
            tempValueForAdditionalChars = splittedValue[0] + '~';
            split[1] = splittedValue[1];
          }
          const fieldCompare = this.profileList.find(
            (profile) => profile.Code === split[1]
          );
          this.addFormulaField(fieldCompare, tempValueForAdditionalChars);
          const model = {
            Field: {
              Name: field.Name,
              Code: field.Code,
              Description: field.Description,
            },
            CompareType: fieldCompare ? 1 : 0,
            ValueCompare: fieldCompare ? null : compare,
            FieldCompare: fieldCompare
              ? {
                  Name: fieldCompare.Name,
                  Code: fieldCompare.Code,
                  Description: fieldCompare.Description,
                }
              : null,
            Operator: splitOperator,
            Operand: '',
          };
          sets.push(model);
        }
      }
    });
    return sets;
  }

  generateGroupCompare(group: string): any[] {
    group = group.substr(1, group.length - 2);
    const values = group.split(/[\+\-\/\*]{1}/);
    const operators = [];
    if (!(group.charAt(0) === '+' || group.charAt(0) === '-')) {
      group = '+' + group;
    }
    for (let i = 0; i < group.length; i++) {
      const char = group.charAt(i);
      if (['+', '-', '*', '/'].indexOf(char) > -1) {
        operators.push(char);
      }
    }
    return values.map((value, i) => {
      let tempValueForAdditionalChars = '';
      if (value.includes('~')) {
        const splittedValue = value.split('~');
        tempValueForAdditionalChars = splittedValue[0] + '~';
        value = splittedValue[1];
      }
      let field = this.profileList.find((profile) => profile.Code === value);
      if (!field && isNaN(+value)) {
        throw new Error('value');
      }
      this.addFormulaField(field, tempValueForAdditionalChars);
      const operator = operators[i];
      const model = {
        Operator: operator,
        CompareType: field ? 1 : 0,
        ValueCompare: field ? null : value,
        FieldCompare: field
          ? {
              Name: field.Name,
              Code: field.Code,
              Description: field.Description,
            }
          : null,
      };
      return model;
    });
  }

  addStaticValuesToProfileList() {
    const staticList = [
      { Code: 'SubTotalIncludingFx', Name: 'SubTotalIncludingFx' },
      { Code: 'OptionProfile', Name: 'OptionProfile' },
      { Code: 'SinglePackage', Name: 'SinglePackage' },
    ];
    this.profileList = this.profileList.concat(staticList);
  }

  getProfiles(): void {
    this.isLoading = true;
    this.pricingCostService.getCostFields().subscribe((result) => {
      result.list.forEach((cost_field) => {
        if (this.isFieldNameValid(cost_field.Name)) {
          this.profileList.push(cost_field);
        }
      });
      this.pricingCostService.getPricingFields().subscribe((price_result) => {
        price_result.list.forEach((price_field) => {
          if (this.isFieldNameValid(price_field.Name)) {
            price_field.Name = price_field.Name + ' (PricingInputField)';
            this.profileList.push(price_field);
          }
        });
        this.addStaticValuesToProfileList();
        this.isLoading = false;
        this.mentionItems.push({
          items: this.profileList,
          triggerChar: '#',
          labelKey: 'Name',
          mentionSelect: this.formatFormulaField,
        }); //labelKey: 'Name', ysf
        this.mentionItems.push({
          items: this.packageNames,
          triggerChar: '@',
          labelKey: 'Name',
          mentionSelect: this.formatFormulaPackageField,
        });
        this.mentionItems.push({
          items: this.lineNames,
          triggerChar: '$',
          labelKey: 'Name',
          mentionSelect: this.formatFormulaLineField,
        });
        // this.mentionItems.push({items:[{name:"a", code:"a"},{name:"b", code:"b"},{name:"c", code:"c"}], triggerChar: '#', labelKey: 'name', mentionSelect:this.formatFormulaField});
      });
    });
  }

  getSeries(): void {
    this.isLoading = true;
    const searchModel = {
      makecode: this.model.MakeCode,
    };
    this.bodyTypeList = [];
    this.modelList = [];
    this.model.BodyTypeCode = null;
    this.model.ModelCode = null;
    this.model.SerieCode = null;
    this.serieService.getAllSeries(searchModel).subscribe(
      (result) => {
        this.serieList = result;
        this.serieList.sort((a, b) => a.Code - b.Code);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  getBodyTypesBySerie() {
    this.isLoading = true;
    const searchModel = {
      seriescode: this.model.SerieCode,
      makecode: this.model.MakeCode,
    };
    this.modelList = [];
    this.model.ModelCode = null;
    this.bodyService.getAllBodyTypes(searchModel).subscribe(
      (res) => {
        this.bodyTypeList = res;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  isFieldNameValid(name: string): boolean {
    return (
      [
        'Name',
        'Description',
        'Type',
        'Code',
        'Id',
        '_Id',
        'CreateDate',
        'UpdateDate',
        'isDeleted',
        'IsBodyIncluded',
        'IsCalculated',
      ].indexOf(name) === -1
    );
  }

  cancel(): void {
    this.reset();
  }

  confirm(): void {
    const requests = this.payload.map((rule) =>
      this.pricingCostService.savePricingCostRule(rule, this.rules.length)
    );
    forkJoin(requests).subscribe((responses) => {
      if (responses.every((res) => res.IsSuccess)) {
        this.alertify.success('Formula saved successfully. ');
      } else {
        this.alertify.error('Please check the formula and try again.');
      }
    });
  }

  onChangeMakeCode(makeCode: string): void {
    this.model.MakeCode = makeCode;
    this.getSeries();
  }

  getModelsBySerieAndBodyType() {
    this.isLoading = true;
    const searchModel = {
      makecode: this.model.MakeCode,
      seriescode: this.model.SerieCode,
      bodytypecode: this.model.BodyTypeCode,
    };
    this.modelService.getAllModels(searchModel).subscribe(
      (res) => {
        this.modelList = res;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  addFormulaField(field, tempValueForAdditionalChars?): void {
    if (
      !field ||
      this.formulaFields.some(
        (formulaField) =>
          formulaField.code === field.Code ||
          formulaField.code == tempValueForAdditionalChars + field.Code
      )
    ) {
      return;
    }
    const code = tempValueForAdditionalChars.length
      ? tempValueForAdditionalChars + field.Code
      : field.Code;
    this.formulaFields.push({
      code: code,
      value: null,
    });
    this.variableFormulaFields.push({
      code: code,
      value: null,
    });
  }

  calculate(): void {
    if (
      this.formulaFields.every(
        (field) => field.value !== null && field.value !== ''
      )
    ) {
      const scope = {};
      console.log('formulaFields', this.formulaFields);
      this.formulaFields.forEach((field) => {
        let scopeName = field.code.includes('~')
          ? this.replaceChar(field.code, '~', '')
          : field.code;
        if (!isNaN(+scopeName['0'])) scopeName = '_' + scopeName; //rakamla başlayan değişkenleri mathjs kabul etmediği için
        scope[scopeName] = field.value;
      });
      try {
        this.rules.forEach((parent) => {
          console.log('parent', parent);
          console.log('scope', scope);
          const showResult = this.getShowResultForCalculateRule(parent, scope);
          console.log(showResult);
          if (showResult) {
            const result = parent.RuleResult[0].includes('~')
              ? this.getRuleForCalculate(parent.RuleResult[0])
              : parent.RuleResult[0];
            this.simulationResult =
              parent.RuleResult[0] +
              ' = ' +
              math.parse(result).compile().evaluate(scope);
            throw new Error('break');
          }
        });
      } catch (e) {}
    }
  }

  getRuleForCalculate(rule: string) {
    if (!isNaN(+rule)) return rule;

    let firstChar = rule.charAt(0);
    if (!isNaN(+firstChar)) {
      rule = '_' + rule;
    }
    return this.replaceChar(rule, '~', '').replace('=', '==');
  }

  getShowResultForCalculateRule(parent, scope) {
    let result: Boolean = true;
    parent.Rules.forEach((rule) => {
      if (isNaN(+rule) && rule != '1>0')
        rule = this.getChangedRuleForCalculate(rule);
      result =
        result && math.parse(rule.replace('=', '==')).compile().evaluate(scope);
    });
    console.log(result);
    return result;
  }

  getChangedRuleForCalculate(rule: string) {
    //eşitliğin sağ tarafı normal sayı ise methoda gitmemeli
    let splitOperator = this.getSplitOperatorFromRule(rule);
    let splittedRule = rule.split(splitOperator);
    let leftCondition = this.getRuleForCalculate(splittedRule[0]);
    let rightCondition = this.getRuleForCalculate(splittedRule[1]);
    return leftCondition + splitOperator + rightCondition;
  }

  getSplitOperatorFromRule(rule: string) {
    let splitOperator = null;
    if (rule.indexOf('>=') > -1) {
      splitOperator = '>=';
    } else if (rule.indexOf('<=') > -1) {
      splitOperator = '<=';
    } else if (rule.indexOf('>') > -1) {
      splitOperator = '>';
    } else if (rule.indexOf('<') > -1) {
      splitOperator = '<';
    } else if (rule.indexOf('=') > -1) {
      splitOperator = '=';
    } else if (rule.indexOf('!=') > -1) {
      splitOperator = '!=';
    }
    return splitOperator;
  }

  replaceChar(value: string, oldChar: string, newChar: string) {
    return value.split(oldChar).join(newChar);
  }

  calculateVariable(): void {
    if (
      this.resultInput &&
      this.selectedVariableCode &&
      this.variableFormulaFields
        .filter((field) => field.code !== this.selectedVariableCode)
        .every((field) => field.value !== null && field.value !== '')
    ) {
      this.findVariable(this.rawFormula, this.selectedVariableCode);
    }
  }

  isFormValid(): boolean {
    // return this.selectedProfile && this.rawFormula && this.model.MakeCode
    //   && this.model.SerieCode && this.model.BodyTypeCode && this.model.ModelCode && this.model.Version;
    return (
      this.selectedProfile &&
      this.rawFormula &&
      this.model.MakeCode &&
      this.model.Version
    );
  }

  addTagToPackageNameFromFormula() {
    this.endFormula = this.rawFormula;
    this.packageNames.forEach((p) => {
      if (this.endFormula.includes(p['Name'])) {
        this.endFormula = this.endFormula
          .split(p['Name'])
          .join('p_' + p['Name']);
      }
    });
    this.lineNames.forEach((l) => {
      if (this.endFormula.includes(l['Name'])) {
        this.endFormula = this.endFormula
          .split(l['Name'])
          .join('l_' + l['Name']);
      }
    });
  }

  fillSpecificFormulaToArray() {
    this.setDisableSpecificInputs(true);
    this.specificFormulaValues = this.specificFormulaForm.value['rows'];
    this.addTagToPackageAndLineNameToSpecificFormulas();

    if (this.specificSinglePackageFormula) {
      this.specificFormulaValues = this.specificFormulaValues.filter(
        (x) => x.Name != 'Single Package'
      );
      this.specificFormulaValues.push({
        Name: 'Single Package',
        FormulaValue:
          'l_' + this.specificSinglePackageFormula + '~SinglePackage',
      });
    }
  }

  setDisableSpecificInputs(isDisable: boolean) {
    let rows = this.getSpecificFormulaRows();
    if (isDisable) rows.disable();
    else rows.enable();
  }

  addTagToPackageAndLineNameToSpecificFormulas() {
    this.specificFormulaValues.forEach((formula) => {
      this.packageNames.forEach((p) => {
        if (formula.FormulaValue.includes(p['Name'])) {
          formula.FormulaValue = formula.FormulaValue.split(p['Name']).join(
            'p_' + p['Name']
          );
        }
      });
    });

    this.lineNames.forEach((l) => {
      this.specificFormulaValues.forEach((formula) => {
        if (formula.FormulaValue.includes(l['Name'])) {
          formula.FormulaValue = formula.FormulaValue.split(l['Name']).join(
            'l_' + l['Name']
          );
        }
      });
    });
  }

  findVariable(formula: string, code: string): void {
    formula = formula.replace(/\s+/g, '');
    const index = formula.indexOf(code);
    const firstChar = formula[index - 1];
    const lastChar = formula[index + code.length];
    let found = null;
    let operator = null;
    if ((firstChar === '-' || firstChar === '+') && !lastChar) {
      found = firstChar + code;
      operator = firstChar;
    } else if (!firstChar && (lastChar === '-' || lastChar === '+')) {
      found = '+' + code;
      operator = '+';
    } else if (
      (firstChar === '-' || firstChar === '+') &&
      (lastChar === '-' || lastChar === '+')
    ) {
      found = firstChar + code;
      operator = firstChar;
    }
    if (found) {
      const scope = {};
      this.variableFormulaFields.forEach((field) => {
        scope[field.code] = field.value;
      });
      try {
        formula = formula.replace(firstChar ? found : code, '');
        const resultFirstChar = this.resultInput[0];
        if (resultFirstChar === '-' || resultFirstChar === '+') {
          formula +=
            (resultFirstChar === '+' ? '-' : '+') +
            this.resultInput.substr(1, this.resultInput.length);
        } else {
          formula += '-' + this.resultInput;
        }
        this.variableSimulationResult = math
          .parse((operator === '+' ? '-' : '') + '(' + formula + ')')
          .compile()
          .evaluate(scope);
      } catch (e) {
        this.alertify.error('Please check the formula and try again.');
      }
    }
  }
}
