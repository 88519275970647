import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @ViewChild("modalBody") modalBody: ElementRef;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Output() versionInputChange = new EventEmitter<string>();
  @Input() modalId: string;
  @Input() header: string;
  @Input() message: string;
  @Input() body: string;
  @Input() versionInput: string;
  @Input() isConfirmProductFile: boolean;
  @Input() isLoading: boolean;
  @Input() loadingText: string;
  @Input() submitButtonText: string;
  @Input() cancelButtonText: string;
  @Input() showCancelButton: boolean;

  private _element: string;
  @Input() public get element(): string {
    return this._element;
  }
  public set element(value: string) {
    this._element = value;
    if (value) this.renderer2.setProperty(this.modalBody.nativeElement, 'innerHTML', value);
  }

  constructor(private renderer2: Renderer2) { }

  ngOnInit() {
      }
  show(): void {
    $(`#${this.modalId}`).modal("show");
  }

  hide(): void {
    $(`#${this.modalId}`).modal("hide");
  }

  submit(): void {
    this.onSubmit.emit(true);
    this.hide();
  }

  updateVersionInput(value: string) {
    this.versionInput = value;
    this.versionInputChange.emit(value);
  }
}
