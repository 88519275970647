<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li [attr.data-target]="'#carouselExampleIndicators'" [attr.data-slide-to]="i" [ngClass]="{'active': i === 0}" *ngFor="let image of imageSources; let i = index"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item" [ngClass]="{'active': i === 0}" *ngFor="let image of imageSources; let i = index">
      <img class="d-block w-100" [src]="image" [alt]="'Foto ' + (i + 1)">
    </div>
  </div>

</div>
