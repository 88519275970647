<div id="main" role="main">

  <!-- MAIN CONTENT -->

  <form class="lockscreen animated flipInY" [formGroup]="lockedFormGroup">
    <div class="logo" style="text-align: center;">
 <img src="../../../../assets/configease.png" alt="" width="250" >
    </div>
    <div>
      <div class="text-center mb-3">
        <img src="../../../../assets/images/icons/user750.png" alt="" width="120" height="120">
      </div>
      <div *ngIf="session_user_info">
        <h5 class="text-center"><i class="pi pi-user fa-3x text-muted air air-top-right hidden-mobile"></i>&nbsp;{{session_user_info.username}}</h5>
        <h5 class="text-center"><i class="pi pi-lock text-muted"></i> &nbsp;Locked</h5>
        <p class="text-muted">
          <a href="mailto:simmons@smartadmin">{{session_user_info.email}}</a>
        </p>

        <div class="input-group">
          <input class="form-control" type="password" placeholder="Password" formControlName="password">
          <div class="input-group-btn">
            <button class="btn btn-primary" [disabled]="!lockedFormGroup.valid" (click)="unlock($event)">
              <ng-container *ngIf="!isLoading">
                <i class="pi pi-key"></i>
              </ng-container>
              <ng-container *ngIf="isLoading">
                <i class="pi pi-spinner"></i>
              </ng-container>

            </button>
          </div>
        </div>
        <p class="no-margin margin-top-5">
          Logged as someone else? <a routerLink="/auth/login"> Click here</a>
        </p>
      </div>

    </div>
    <p class="font-xs margin-top-5">
      Copyright REA Technology .

    </p>
  </form>

</div>
