import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent {

  imageSources = [
    '../../../assets/p.png',
    '../../../assets/p.png',
    '../../../assets/p.png',
    // ... diğer fotoğraf yolları
  ];

  imageIndex = 0;
  interval: any;

  constructor() {
    this.startCarousel();
  }

  startCarousel() {
    this.interval = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  nextSlide() {
    this.imageIndex = (this.imageIndex + 1) % this.imageSources.length;
  }



}

