import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../../shared/smartadmin.config';
import { catchError, delay, map, Observable, throwError } from 'rxjs';
@Injectable()
export class ModelService {
  constructor(private http: HttpClient) {}

  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + config.API_URL + url).pipe(
      delay(100),
      map((data: any) => data.data || data),
      catchError(this.handleError)
    );
  }

  private getBaseUrl() {
    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      '/'
    );
  }

  public createNewModel(model: any): Observable<any> {
    let body = JSON.stringify(model);
    return this.http.post(config.API_ENDPOINT + 'model/save', body);
  }

  public getAllModelsBySerie(serie_no): Observable<any> {
    return this.http.get(
      config.API_ENDPOINT + 'model/getbyseriescode?seriescode=' + serie_no
    );
  }

  public getAllModels(search_model): Observable<any> {
    let body = JSON.stringify(search_model);
    return this.http.post(config.API_ENDPOINT + 'model/listall', body);
  }

  public deleteModel(model: any): Observable<any> {
    let body = JSON.stringify(model);
    return this.http.post(config.API_ENDPOINT + 'model/delete', body);
  }

  public getModelByCode(model_code): Observable<any> {
    return this.http.get(
      config.API_ENDPOINT + 'model/getbycode?code=' + model_code
    );
  }

  public getModelById(model_id): Observable<any> {
    return this.http.get(config.API_ENDPOINT + 'model/get?id=' + model_id);
  }
  private handleError(error: any) {
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
  }
}
