import { DatePipe } from '@angular/common';
import { ApplicationRef, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutsModule } from './layouts/layouts.module';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState } from './app.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ServerErrorsInterceptor } from './core/serverErrorsInterceptor';
import { NotificationService } from './shared/utils/notification.service';
import { AuthService } from './core/services/auth.service';
import { ModelService } from './core/services/model.service';
import { BodyTypeService } from './core/services/body-type.service';
import { PriceService } from './core/services/price.service';
import { PricingCostService } from './core/services/pricing-cost.service';
import { LineService } from './core/services/line.service';
import { SerieService } from './core/services/serie.service';
import { ProductFileService } from './core/services/product-file.service';
import { PriceOverviewService } from './core/services/price-overview.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ErrorsHandlers } from './core/ErrorsHandlers';
import { LoadingInterceptor } from './core/loading.interceptor';
import { AuthGuard } from './core/guards/auth.guard';

const APP_PROVIDERS = [...APP_RESOLVER_PROVIDERS, AppState];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    LayoutsModule,
    SharedModule,
    PagesModule,
    HttpClientModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    ProgressSpinnerModule,
    HttpModule,
  ],
  exports: [FormsModule, ReactiveFormsModule],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    NotificationService,
    AuthService,
    ModelService,
    BodyTypeService,
    PriceService,
    PricingCostService,
    LineService,
    SerieService,
    ProductFileService,
    PriceOverviewService,
    AuthGuard,
    APP_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    // APP_PROVIDERS,
    // {
    //   provide: ErrorHandler,
    //   useClass: ErrorsHandlers
    // },
  ],
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public appState: AppState) {}
}
