<div id="table-container">
  <div class="formula-container">
    <div class="cell-name">
      {{ selectedCell.name }}
    </div>
    <div class="formula-input">
      <img src="../../../../assets/images/formula.png" />
      <input type="text" [(ngModel)]="selectedCell.newValue" [disabled]="selectedCell.disabled"
        (focus)="initTribute(formulaInput)"
        (keyup.enter)="selectedCell.value !== selectedCell.newValue ? saveFormula() : null" id="formula-input"
        #formulaInput />
      <div *ngIf="selectedCell.value !== selectedCell.newValue" (click)="saveFormula()" class="save-button">
        <i class="fa fa-check" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div #priceOverviewTable></div>
</div>
