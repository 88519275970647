import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthService } from '../../../core/services/auth.service';
import { NotificationService } from "../../../shared/utils/notification.service";
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AlertifyService } from 'src/app/shared/utils/alertify.service';

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  styleUrls: [
    './locked.component.css'
  ]
})
export class LockedComponent implements OnInit {
  public session_user_info: any = {};
  private login_model: any = {};
  lockedFormGroup: FormGroup;
  isLoading: boolean = false;
  return_url: string;
  constructor(private router: Router, private auth_service: AuthService,
    private notificationService: NotificationService, private activatedRoute: ActivatedRoute,
    private alertfiy:AlertifyService) {
    this.lockedFormGroup = new FormGroup({
      password: new FormControl('', Validators.required)
    });
    this.activatedRoute.params.subscribe((params: Params) => {
      this.return_url = params['return_url'];
    });
  }

  ngOnInit() {
    let user_info = JSON.parse(this.auth_service.getAccessToken());
    if (user_info) {
      this.session_user_info.username = user_info.user_full_name;
      this.session_user_info.email = user_info.user_email;
    }
    else
      this.router.navigate(['/auth/login'])
  }

  unlock(event) {
    this.isLoading = true;
    this.login_model.username = this.session_user_info.email;
    this.login_model.password = this.lockedFormGroup.get('password').value;
    this.auth_service.checkUser(this.login_model).subscribe(result => {
      if (result != null)
        this.router.navigate(['/home']);
      else
      this.alertfiy.error("User password not valid.");
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.alertfiy.error("User password not valid.");
    });
    // this.router.navigate(['/dashboard/+analytics'])
  }



}
