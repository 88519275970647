<form [formGroup]="form" (ngSubmit)="send()" class="email-form">
  <label for="from_name">Full Name:</label>
  <input type="text" formControlName="from_name" id="from_name" placeholder="Full Name">

  <label for="from_email">Email:</label>
  <input type="email" formControlName="from_email" id="from_email" placeholder="Email">

  <label for="subject">Subject:</label>
  <input type="text" formControlName="subject" id="subject" placeholder="Subject">

  <label for="message">Message:</label>
  <textarea formControlName="message" id="message" rows="4" placeholder="Your message"></textarea>

  <button type="submit">Send</button>
</form>
