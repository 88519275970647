export const config: any = {
  defaultLocale: 'us',

  API_URL: '/assets/api',

  API_ENDPOINT: window['ENV'].endpoint,
  API_TOKEN: window['ENV'].token,

  menu_speed: 200,

  GOOGLE_API_KEY: 'AIzaSyDd8YW8k_J-Jkti-W4QNk5dL8O_5_2QUWY',

  sound_path: 'assets/sound/',
  sound_on: true,

  /**
   *  VOICE CONTROL
   */
  voice_command: true,
  voice_command_auto: false,

  voice_command_lang: 'en-US',
  /**
   *  Use localstorage to remember on/off (best used with HTML Version)
   */
  voice_localStorage: false,
  /**
   * Voice Commands
   * Defines all voice command variables and functions
   */

  voice_commands: {
    'show dashboard': {
      type: 'navigate',
      payload: ['/dashboard/+analytics'],
    },
    'show +social': {
      type: 'navigate',
      payload: ['/dashboard/+social'],
    },
    'show outlook': {
      type: 'navigate',
      payload: ['/outlook'],
    },
    'show graphs': {
      type: 'navigate',
      payload: ['/graphs/chart-js'],
    },
    'show flot chart': {
      type: 'navigate',
      payload: ['/graphs/flot-charts'],
    },
    'show morris chart': {
      type: 'navigate',
      payload: ['/graphs/morris-charts'],
    },
    'show inline chart': {
      type: 'navigate',
      payload: ['/graphs/sparklines'],
    },
    'show dygraphs': {
      type: 'navigate',
      payload: ['/graphs/dygraphs'],
    },
    'show tables': {
      type: 'navigate',
      payload: ['/tables/normal'],
    },
    'show data table': {
      type: 'navigate',
      payload: ['/tables/datatables'],
    },
    'show form': {
      type: 'navigate',
      payload: ['/forms/elements'],
    },
    'show form layouts': {
      type: 'navigate',
      payload: ['/forms/layouts'],
    },
    'show form validation': {
      type: 'navigate',
      payload: ['/forms/validation'],
    },
    'show form elements': {
      type: 'navigate',
      payload: ['/forms/bootstrap-elements'],
    },
    'show form plugins': {
      type: 'navigate',
      payload: ['/forms/plugins'],
    },
    'show form wizards': {
      type: 'navigate',
      payload: ['/forms/wizards'],
    },
    'show bootstrap editor': {
      type: 'navigate',
      payload: ['/forms/editors'],
    },
    'show dropzone': {
      type: 'navigate',
      payload: ['/forms/dropzone'],
    },
    'show image cropping': {
      type: 'navigate',
      payload: ['/forms/image-cropping'],
    },
    'show general elements': {
      type: 'navigate',
      payload: ['/ui/general-elements'],
    },
    'show buttons': {
      type: 'navigate',
      payload: ['/ui/buttons'],
    },
    'show fontawesome': {
      type: 'navigate',
      payload: ['/ui/icons/font-awesome'],
    },
    'show glyph icons': {
      type: 'navigate',
      payload: ['/ui/icons/glyphicons'],
    },
    'show flags': {
      type: 'navigate',
      payload: ['/ui/icons/flags'],
    },
    'show grid': {
      type: 'navigate',
      payload: ['/ui/grid'],
    },
    'show tree view': {
      type: 'navigate',
      payload: ['/ui/treeviews'],
    },
    'show nestable lists': {
      type: 'navigate',
      payload: ['/ui/nestable-lists'],
    },
    'show jquery U I': {
      type: 'navigate',
      payload: ['/ui/jquery-ui'],
    },
    'show typography': {
      type: 'navigate',
      payload: ['/ui/typography'],
    },
    'show calendar': {
      type: 'navigate',
      payload: ['/calendar'],
    },
    'show widgets': {
      type: 'navigate',
      payload: ['/widgets'],
    },
    'show gallery': {
      type: 'navigate',
      payload: ['/app-views/gallery'],
    },
    'show maps': {
      type: 'navigate',
      payload: ['/maps'],
    },
    'go back': (): any => {
      history.back();
    },
    'hide navigation': {
      type: 'layout',
      payload: 'hide navigation',
    },
    'show navigation': {
      type: 'layout',
      payload: 'show navigation',
    },
    mute: {
      type: 'sound',
      payload: 'mute',
    },
    'sound on': {
      type: 'sound',
      payload: 'sound on',
    },
    stop: {
      type: 'voice',
      payload: 'stop',
    },
    help: {
      type: 'voice',
      payload: 'help on',
    },
    'got it': {
      type: 'voice',
      payload: 'help off',
    },
    logout: {
      type: 'navigate',
      payload: ['/auth/login'],
    },
  },
};

// required for SmartNotification
// window['jQuery'].sound_on = config.sound_on;
// window['jQuery'].sound_path = config.sound_path;
