import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'make-codes',
  templateUrl: './make-codes.component.html',
})
export class MakeCodesComponent implements OnInit {
  @Output() valueChangeMakeCode: EventEmitter<any> = new EventEmitter();
  @Input() makecode: any;
  @Input() selectclass: any;
  @Input() style: any;
  @Input() disabled = false;
  public makeCodes = [];
  public makeCodeVal: any;
  constructor(private auth_service: AuthService) {
    if (!this.selectclass) this.selectclass = 'form-control';
    if (!this.style) this.style = { 'max-width': '50%' };
  }

  ngOnInit() {
    let session_user_info = JSON.parse(this.auth_service.getAccessToken());
    if (session_user_info.user_make_codes) {
      let makecodes = session_user_info.user_make_codes.split(',');
      this.makeCodes = makecodes;
    } else {
      // hard coded makecode data
      this.makeCodes.push('H476E CARGO');
      // ulke kodu ile degisitrilecek.
    }
  }

  changeMakeCode(target) {
    this.valueChangeMakeCode.emit(target.value);
  }
}
