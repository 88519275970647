<body
  class="layout-admin aside-sticky bg-lime header-sticky"
  data-s2t-class="btn-primary btn-sm bg-gradient-default rounded-circle border-0"
>
  <div id="wrapper" class="d-flex align-items-stretch flex-column">
    <!--  header -->
    <header id="header" class="d-flex align-items-center shadow-xs">
      <!-- Navbar -->
      <div class="container-fluid position-relative">
        <nav
          class="navbar navbar-expand-lg navbar-light justify-content-between"
        >
          <!-- logo, navigation toggler -->
          <div class="align-items-start">
            <!-- sidebar toggler -->
            <a
              href="#aside-main"
              class="btn-sidebar-toggle h-100 d-inline-block d-lg-none justify-content-center align-items-center p-2"
            >
              <span>
                <svg width="25" height="25" viewBox="0 0 20 20">
                  <path
                    d="M 19.9876 1.998 L -0.0108 1.998 L -0.0108 -0.0019 L 19.9876 -0.0019 L 19.9876 1.998 Z"
                  ></path>
                  <path
                    d="M 19.9876 7.9979 L -0.0108 7.9979 L -0.0108 5.9979 L 19.9876 5.9979 L 19.9876 7.9979 Z"
                  ></path>
                  <path
                    d="M 19.9876 13.9977 L -0.0108 13.9977 L -0.0108 11.9978 L 19.9876 11.9978 L 19.9876 13.9977 Z"
                  ></path>
                  <path
                    d="M 19.9876 19.9976 L -0.0108 19.9976 L -0.0108 17.9976 L 19.9876 17.9976 L 19.9876 19.9976 Z"
                  ></path>
                </svg>
              </span>
            </a>

            <!-- logo : mobile only -->
            <a class="navbar-brand d-inline-block d-lg-none mx-2" href="/admin">
              <img
                src="assets/images/logo/logo_sm.svg"
                width="38"
                height="38"
                alt="..."
              />
            </a>
          </div>

          <!-- navbar : navigation -->
          <div class="collapse navbar-collapse" id="navbarMainNav">
            <!-- navbar : mobile menu -->
            <div class="navbar-xs d-none">
              <!-- close button -->
              <button
                class="navbar-toggler pt-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarMainNav"
                aria-controls="navbarMainNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <svg width="20" viewBox="0 0 20 20">
                  <path
                    d="M 20.7895 0.977 L 19.3752 -0.4364 L 10.081 8.8522 L 0.7869 -0.4364 L -0.6274 0.977 L 8.6668 10.2656 L -0.6274 19.5542 L 0.7869 20.9676 L 10.081 11.679 L 19.3752 20.9676 L 20.7895 19.5542 L 11.4953 10.2656 L 20.7895 0.977 Z"
                  ></path>
                </svg>
              </button>

              <!-- logo -->
              <a class="navbar-brand" href="/admin">
                <img
                  src="assets/images/logo/logo_dark.svg"
                  width="110"
                  height="38"
                  alt="..."
                />
              </a>
            </div>
            <!-- /navbar : mobile menu -->
          </div>
          <!-- /navbar : navigation -->

          <!-- options -->
          <ul class="log-out-container" style="margin-bottom: 0">
            <li class="list-inline-item">
              <p>{{ getUsername() }}</p>
            </li>
            <li class="list-inline-item mx-3">
              <a
                href="#/auth/login"
                (click)="logOut()"
                class="btn btn-sm btn-linkedin transition-hover-top"
                rel="noopener"
              >
                <span>Log Out</span>
              </a>
            </li>
          </ul>
          <!-- /options -->
        </nav>
      </div>
      <!-- /Navbar -->
    </header>
    <!-- /Header -->

    <div id="wrapper_content" class="d-flex flex-fill">
      <main id="middle" style="width: 50vw" class="flex-fill mx-auto">
        <router-outlet></router-outlet>
      </main>
    </div>
    <!-- content -->
    <div id="wrapper_content" class="d-flex flex-fill">
      <!-- sidebar -->
      <aside
        id="aside-main"
        class="aside-start aside-hide-xs bg-white shadow-sm d-flex flex-column px-2 h-auto"
      >
        <!-- sidebar : logo -->
        <div class="py-1 px-1 mb-1 mt-1 ml-0">
          <a href="/#/admin">
            <img
              src="../../../assets/configease.png"
              width="250"
              height="100"
              alt="..."
            />
          </a>
        </div>

        <!-- /sidebar : logo -->

        <!-- sidebar : navigation -->
        <div
          class="aside-wrapper scrollable-vertical scrollable-styled-light align-self-baseline h-100 w-100"
        >
          <!--sidebar-->
          <nav class="nav-deep nav-deep-sm nav-deep-light">
            <ul class="nav flex-column">
              <c-sidebar
                #sidebar="cSidebar"
                class="d-print-none sidebar sidebar-fixed"
                id="sidebar"
                visible
              >
                <perfect-scrollbar [config]="perfectScrollbarConfig">
                  <c-sidebar-nav [navItems]="navItems" dropdownMode="close">
                  </c-sidebar-nav>
                </perfect-scrollbar>
              </c-sidebar>
            </ul>
          </nav>
        </div>

        <!-- sidebar : footer -->
        <div
          class="d-flex align-self-baseline w-100 py-3 px-3 border-top border-light small"
        >
          <p class="d-inline-grid gap-auto-3 mb-0">
            <svg
              class="text-gray-600"
              width="22px"
              height="22px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.052 512.052"
              fill="currentColor"
            >
              <path
                d="M208.026,16.026c-114.7,0-208,78.9-208,176c0,39.8,15.7,77.9,44.5,108.9l-39.8,39.8c-4.6,4.6-6,11.5-3.5,17.4c2.5,6,8.3,9.9,14.8,9.9h192c114.7,0,208-78.9,208-176S322.726,16.026,208.026,16.026z"
              ></path>
              <path
                opacity="0.5"
                d="M467.526,428.926c28.8-30.9,44.5-69.1,44.5-108.9c0-49.8-24.6-94.9-64-126.9c-0.9,114.1-108.2,206.9-240,206.9h-89.2c34.5,56.9,104.6,96,185.2,96h192c6.5,0,12.3-3.9,14.8-9.9c2.5-6,1.1-12.9-3.5-17.4L467.526,428.926z"
              ></path>
              <path
                fill="#ffffff"
                d="M304.026,144.026h-192c-8.8,0-16,7.2-16,16s7.2,16,16,16h192c8.8,0,16-7.2,16-16S312.826,144.026,304.026,144.026z"
              ></path>
              <path
                fill="#ffffff"
                d="M240.026,208.026h-128c-8.8,0-16,7.2-16,16s7.2,16,16,16h128c8.8,0,16-7.2,16-16S248.826,208.026,240.026,208.026z"
              ></path>
            </svg>
            <a href="#" class="link-normal text-dashed">Need help?</a>
          </p>
        </div>
        <!-- /sidebar : footer -->
      </aside>
      <!-- /sidebar -->
    </div>
    <!-- /content -->

    <!-- footer -->
    <footer id="footer" class="bg-white shadow">
      <div class="p-3">
        <span class="small fw-medium">&copy; Rea Technology</span>

        <div class="d-inline-block float-end dropdown">
          <ul class="list-inline m-0">
            <!-- LANGUAGE -->
            <li class="dropdown list-inline-item m-0">
              <a
                id="topDDLanguage"
                href="#"
                class="d-inline-block"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <i class="flag flag-us"></i>
                <span class="small px-2">English</span>
              </a>

              <div
                aria-labelledby="topDDLanguage"
                class="dropdown-menu dropdown-menu-clean shadow-lg small m-0 max-vh-50 scrollable-vertical dropdown-menu-right"
              >
                <a href="#" class="dropdown-item text-primary-hover active">
                  <i class="flag flag-us"></i>
                  English
                </a>
                <a href="#" class="dropdown-item text-primary-hover">
                  <i class="flag flag-de"></i>
                  German
                </a>
                <a href="#" class="dropdown-item text-primary-hover">
                  <i class="flag flag-fr"></i>
                  Francaise
                </a>
              </div>
            </li>
            <!-- /LANGUAGE -->
          </ul>
        </div>
      </div>
    </footer>
    <!-- /footer -->
  </div>
  <!-- /#wrapper -->
</body>
