




<header id="header" class="shadow-xs">

  <nav appNavbarScroll class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light" id="navbar">
    <div class="container-fluid">

      <div class="navbar-brand logo text-uppercase" href="index-1.html" *ngIf="!isMobile">
        <div class="logo-container" style="max-width: 300px; max-height: 100px;">
          <img src="../../../../assets/configease.png" class="logo-light" alt="Light Logo" style="width: 100%; height: 100%;">
          <img src="../../../../assets/configease.png" class="logo-dark" alt="Dark Logo" style="width: 100%; height: 100%;">
        </div>
      </div>
      <div class="navbar-brand logo text-uppercase" href="index-1.html" *ngIf="isMobile">
        <div class="logo-container" style="max-width: 230px; max-height: 150px;">
          <img src="../../../../assets/configease.png" class="logo-light" alt="Light Logo" style="width: 100%; height: 100%;">
          <img src="../../../../assets/configease.png" class="logo-dark" alt="Dark Logo" style="width: 100%; height: 100%;">
        </div>
      </div>


      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav ms-auto" id="navbar-navlist" *ngIf="!isMobile">
              <li class="nav-item">
                  <a class="nav-link" href="index.html">Home</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="index.html">About</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="index.html">FeaTures</a>
              </li>

              <li class="nav-item">
                <a class="nav-link" href="index.html">IMAGES
                </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="index.html">REFERENCES
              </a>
          </li>


          </ul>


          <div class="ms-auto" *ngIf="!isMobile">
            <a href="index.html" aria-label="Account Demo Request" class="btn bg-gradiant">
              <span class="group-icon">
                <i class="fi fi-link"></i>
                <i class="fi fi-close"></i>
              </span>
              <span>REQUEST DEMO</span>
            </a>


          </div>


          <ul class="navbar-nav ms-auto" id="navbar-navlist" *ngIf="isMobile">
            <img src="../../../../assets/configease.png" class="logo-dark" alt="" height="90" width="auto">
            <br>
            <li class="nav-item">
                <a class="nav-link" href="index.html">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="index.html">About</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="index.html">FeaTures</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="index.html">IMAGES
              </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="index.html">REFERENCES
            </a>
        </li>

          <li class="nav-item" >
            <a href="index.html" class="nav-link">
          REQUEST DEMO
            </a>
          </li>
      <!---  <li class="nav-item" >
            <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active" aria-label="Account Sign In"
            class="nav-link">

       SIGN IN
          </a>
          </li>-->



        </ul>
        <a href="index.html">
          <button class="navbar-toggler pt-0" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarMainNav" aria-controls="navbarMainNav" aria-expanded="false"
          aria-label="Toggle navigation" style="text-align: right;">
          <svg width="20" viewBox="0 0 20 20">
            <path
              d="M 20.7895 0.977 L 19.3752 -0.4364 L 10.081 8.8522 L 0.7869 -0.4364 L -0.6274 0.977 L 8.6668 10.2656 L -0.6274 19.5542 L 0.7869 20.9676 L 10.081 11.679 L 19.3752 20.9676 L 20.7895 19.5542 L 11.4953 10.2656 L 20.7895 0.977 Z">
            </path>
          </svg>
        </button>
</a>




      </div>

  </div>
</nav>


</header>
<form class="login" [formGroup]="login_form_group">
  <img src="../../../../assets/configease.png" style="width: 280px; max-width: 100%; display: block; margin: 0 auto 20px; ">
  <input required type="username" class="form-control" id="account_email" placeholder="Username" formControlName="email">

  <input required type="password" class="form-control" id="account_passwd" formControlName="password" placeholder="Password" autocomplete="new-password">

  <button type="submit" class="btn bg-gradiant-sign" (click)="login($event)" [disabled]="!login_form_group.valid">
    <span>LOGIN</span>
  </button>
  <div *ngIf="error" class="error-message">{{ error }}</div>
</form>

<a href="https://codepen.io/davinci/" target="_blank" class="a">check my other pens</a>



<footer style="position: fixed; bottom: 0; width: 100%; background-color: rgba(0, 0, 0, 0.05);" class="text-center p-4 text-muted">
  © Copyright:
  <a class="text-reset fw-bold" href="https://www.reateknoloji.com/">Rea Technology</a>
</footer>
